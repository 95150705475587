import dayjs from "dayjs";
import { I18n } from "react-redux-i18n";
import { DrivingLicense, Examinee, PeselValidationData } from "../api/examineesApi";

export const isBrowser = typeof window !== "undefined";

export const mobileBreakpointValuePx = 768;

export const SNACKBAR_AUTO_HIDE_DURATION = 10000;

export const MAX_COEXISTING_SNACKBARS = 5;

export const AUTO_LOGOUT_TIME_MINUTES = 15;

export const PROMPT_TIME_MINUTES = 1;

export const emptyState = "–";

export const getDrivingLicenses = (drivingLicenses: DrivingLicense[]) => {
  const LicenseCategories = drivingLicenses.map((drivingLicense) => {
    return drivingLicense.category;
  });

  return LicenseCategories.join(", ");
};

// contact phone numbers & emails
export const userSettingsHelpdeskPhoneNumber = "719 362 324";
export const labSettingsHelpdeskPhoneNumber = "719 362 324";
export const helpdeskContact = {
  phone: [
    "+48 32 249 50 40",
    "+48 32 765 82 24",
    "+48 32 765 82 26",
    "+48 32 797 95 88",
  ],
  email: "alta@alta.pl",
};

// Preferred date formats in the application
export const BackendDateFormat = "YYYY-MM-DD";

export const FormatDate = (
  date: string | undefined | null | Date,
  format = "YYYY-MM-DD"
): string => (date ? dayjs(date).format(format) : emptyState);

// The function that returns a string with a examinee's first and last name or string that it's anonymous
export const CheckAnonymity = (examinee: Examinee) => {
  return examinee?.firstName && examinee?.lastName
    ? `${examinee?.firstName} ${examinee?.lastName}`
    : I18n.t("ExamineeView.anonymity");
};

// Check if object of any type is empty
export const IsObjectEmpty = (obj: Record<string, unknown>): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const HasObjectNull = (obj: Record<string, unknown>): boolean =>
  Object.values(obj).every((value) => value === null);

export const decodePesel = (peselValue: string) => {
  const isFullLengthPesel = peselValue.trim().length === 11;
  if (isFullLengthPesel) {
    let year = parseInt(peselValue.substring(0, 2), 10);
    let month = parseInt(peselValue.substring(2, 4), 10) - 1;
    const day = parseInt(peselValue.substring(4, 6), 10);

    if (month >= 80) {
      year += 1800;
      month -= 80;
    }
    else if (month >= 60) {
      year += 2200;
      month -= 60;
    }
    else if (month >= 40) {
      year += 2100;
      month -= 40;
    }
    else if (month >= 20) {
      year += 2000;
      month -= 20;
    }
    else {
      year += 1900;
    }

    const birthDayValue = dayjs(new Date(year, month, day));

    // Weryfikacja numery PESEL
    const weightArray = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
    let sum = 0;

    for (let i = 0; i < weightArray.length; i++) {
      sum += (parseInt(peselValue.substring(i, i + 1), 10) * weightArray[i]);
    }

    sum %= 10;

    const checkDigit = parseInt(peselValue.substring(10, 11), 10);
    const isCorrect = (sum === checkDigit);
    let sexValue = "female";

    if (parseInt(peselValue.substring(9, 10), 10) % 2 === 1) {
      sexValue = "male";
    }

    const peselData: PeselValidationData = {
      isValid: isCorrect,
      sexValue: isCorrect ? sexValue : "",
      birthDayValue: isCorrect ? birthDayValue.toISOString() : "",
      isFullLengthPesel
    }

    return peselData;
  }
}

export const selectedTheme = () => {
  return "optimisTheme";
  // MF na razie nie ma możliwosci zmiany tematu
  // ale docelowo trzeba tu bedzie zrobić zaczytanie z danych zalogowanego użytkownika
  // return localStorage.getItem("TYPE_OF_THEME") || "optimis";
}

export const getMethodologyName = (methodologyId: number) => {
  let result = "";
  switch (methodologyId) {
    case 1:
      result = I18n.t("ExaminationAssignmentView.labels.methodologyTest2Safe");
      break;
    case 2:
      result = I18n.t("ExaminationAssignmentView.labels.methodologyTest2Arms");
      break;
    case 3:
      result = I18n.t("ExaminationAssignmentView.labels.methodologyTest2Work");
      break;
    case 4:
      result = I18n.t("ExaminationAssignmentView.labels.methodologyTest2Drive");
      break;
  }

  return result;
}
