import { createSlice } from "@reduxjs/toolkit";
import { PsychologicalLabsListState } from "../../../api/psychologicalLabApi";
import { handlePending, handleReject } from "../../../utils/redux";
import { fetchPsychologicalLabs, fetchSerchedPsychologicalLabs } from "../../actions/adminPanel/psychologicalLabsActions";

const initialState: PsychologicalLabsListState = {
  psychologicalLabs: [],
  succeeded: false,
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
  searchedPsychLabs: [],
  searchedFilter: undefined,
  searchedLoading: false,
};

const psychologicalLabsSlice = createSlice({
  name: "psychologicalLabsSlice",
  initialState,
  reducers: {
    clearSearchedPsychLabs(state) {
      state.searchedPsychLabs = [];
    },
    clearSearchBarPsychLabsFilter(state) {
      state.searchedFilter = undefined;
    },
    setSearchPsychLabsBarFilter(state, action) {
      state.searchedFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPsychologicalLabs.pending, handlePending)
      .addCase(fetchPsychologicalLabs.rejected, handleReject)
      .addCase(fetchPsychologicalLabs.fulfilled, (state, action) => {
        state.psychologicalLabs = action.payload;
        state.loading = false;
        state.succeeded = true;
      })
      .addCase(fetchSerchedPsychologicalLabs.pending, (state) => {
        state.searchedPsychLabs = [];
        state.searchedLoading = true;
        state.succeeded = false;
      })
      .addCase(fetchSerchedPsychologicalLabs.rejected, (state) => {
        state.searchedPsychLabs = [];
        state.searchedLoading = false;
        state.succeeded = false;
      })
      .addCase(fetchSerchedPsychologicalLabs.fulfilled, (state, action) => {
        state.searchedPsychLabs = action.payload;
        state.searchedLoading = false;
        state.succeeded = true;
      })
  },
});

export const psychologicalLabsReducer = psychologicalLabsSlice.reducer;
export const {
  clearSearchedPsychLabs,
  setSearchPsychLabsBarFilter,
  clearSearchBarPsychLabsFilter,
} = psychologicalLabsSlice.actions;
