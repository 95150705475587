/*
 * Method handling file download form the axios client request, with responseTypes of "arraybuffer"
 */
import { AxiosResponse } from "axios";

export const downloadFileFromArraybuffer = (
  response: AxiosResponse,
  type = "application/pdf",
  fileName: string | undefined = undefined
) => {
  if (typeof window !== 'undefined') {
    const url = window.URL.createObjectURL(new Blob([response.data], { type }));
    const downloadLink = document.createElement("a");

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const suggestedFilename = response.headers["suggested-filename"];
    const decodedSuggestedFilename =
      suggestedFilename && decodeURI(suggestedFilename as string);

    downloadLink.target = "_blank";
    downloadLink.download = fileName || decodedSuggestedFilename;
    downloadLink.href = url;
    document.body.append(downloadLink);
    downloadLink.click();
  }
};

export const downloadFileXlsFromArraybuffer = (
  response: AxiosResponse,
  fileName: string | undefined = undefined,
  type = "application/vnd.ms-excel"
) => {
  if (typeof window !== 'undefined') {
    const url = window.URL.createObjectURL(new Blob([response.data], { type }));
    const downloadLink = document.createElement("a");

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const suggestedFilename = response.headers["suggested-filename"];
    const decodedSuggestedFilename =
      suggestedFilename && decodeURI(suggestedFilename as string);

    downloadLink.target = "_blank";
    downloadLink.download = fileName || decodedSuggestedFilename;
    downloadLink.href = url;
    document.body.append(downloadLink);
    downloadLink.click();
  }
};
