import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../../api/axiosConfig";
import { PsychLabActivity, PsychLabUser, PsychologicalLab } from "../../../api/psychologicalLabApi";
import { dispatchNotification } from "../../../utils/redux";
import { psychologicalLabsActivityURL, psychologicalLabsURL, psychologicalLabsUserURL } from "../../../utils/paths";
import { FilterPsychLabActivitiesData, StatsPsychLabData } from "../../../components/Forms/types";
import { downloadFileXlsFromArraybuffer } from "../../../utils/download";
import { I18n } from "react-redux-i18n";

export const fetchSelectedPsychLabData = createAsyncThunk<
  PsychologicalLab,
  number,
  AsyncThunkConfig
>("selectedPsychLab/fetchSelectedPsychLabData/get", async (psychLabId, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychologicalLab>>(
      `${psychologicalLabsURL}${psychLabId}/`
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchUsersList = createAsyncThunk<
  PsychLabUser[],
  number,
  AsyncThunkConfig
>("selectedPsychLab/fetchUsersList", async (psychLabId, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychLabUser[]>>(
      psychologicalLabsUserURL, { params: { psychLabId } }
    );
    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchActivityList = createAsyncThunk<
  PsychLabActivity[],
  FilterPsychLabActivitiesData,
  AsyncThunkConfig
>("selectedPsychLab/fetchActivityList", async (psychLabFilters, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<PsychLabActivity[]>>(
      `${psychologicalLabsActivityURL}filtered_list/`,
      { params: psychLabFilters }
    );
    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error");
    throw err;
  }
});

export const fetchStatsList = createAsyncThunk<
  undefined,
  StatsPsychLabData,
  AsyncThunkConfig
>("selectedPsychLab/fetchStatsList", async (psychLabStatsDate, thunkAPI) => {
  try {
    const response = await client.get<Blob>(
      `${psychologicalLabsActivityURL}stats_list/`,
      {
        params: psychLabStatsDate,
        responseType: "arraybuffer",
      }
    );

    if (response.data) {
      downloadFileXlsFromArraybuffer(
        response,
        I18n.t("AdminDashboard.StatsList.statFileName"),
        'application/octet-stream'
      );
    }

  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const extractedError = err.response.data as ApiError;

      dispatchNotification("error", extractedError.message);

      return thunkAPI.rejectWithValue(extractedError);
    }

    dispatchNotification("error", "Wystąpił błąd podczas pobierania statystyk");
    throw err;
  }
});
