/* eslint-disable camelcase */
export const ru = {
  HelpDocs: {
    contractTitle: "Контракты",
    contractName1: "Психологический контракт 2024",
    contractName2: "Психологический контракт RU 2024",
    contractName3: "Психологический контракт UA 2024",
    contractName4: "Политика защиты персональных данных - Информационная оговорка 2024",
    handbookTitle: "Справочник и каталог тестов",
    handbookName1: "Характеристика областей диагностики",
    handbookName2: "Психомоторные тесты",
    handbookName3: "Когнитивные тесты",
    handbookName4: "Тесты личности",
    handbookName5: "Каталог тестов 03-2024",
    modulesTitle: "Модули интервью",
    modulesName1: "Состояние здоровья",
    modulesName2: "Социальная и семейная ситуация",
    modulesName3: "Наблюдение",
    modulesName4: "Детективы защита оружие MTD I",
    modulesName5: "Лицензия MTD II",
    modulesName6: "Медицина труда MTD III",
    modulesName7: "Служебные водители MTD III",
    modulesName8: "Работа на высоте MTD III",
    modulesName9: "Погрузчики MTD III",
    modulesName10: "Транспортная психология управление транспортными средствами MTD IV"
  },
  Format: {
    ViewDateTime: "DD.MM.YYYY HH:mm",
    FormDateFormat: "D MMMM YYYY",
    ExamineeViewDateFormat: "DD.MM.YYYY",
  },
  Buttons: {
    accept: "Согласен",
    addExaminee: "Добавить обследуемого",
    addPsychLab: "Добавить аккаунт клиента",
    addPsychUser: "Добавить аккаунт пользователя",
    addUser: "Добавить аккаунт пользователя",
    addDirectory: "Добавить папку",
    assign: "Назначить",
    alreadyAssigned: "Назначено",
    assignExamination: "Назначить обследование",
    alreadyAssignedExamination: "Обследование в процессе",
    back: "Назад",
    backToLogin: "Вернуться на страницу входа",
    begin: "Начать",
    cancel: "Отменить",
    change: "Изменить",
    clear: "Очистить",
    close: "Закрыть",
    confirm: "Подтвердить",
    continue: "Далее",
    createExamination: "Назначить обследование",
    delete: "Удалить",
    deleteExaminee: "Удалить обследуемого",
    deletePsychLab: "Удалить аккаунт клиента",
    deletePsychLabLic: "Удалить лицензию",
    downloadAll: "Скачать все",
    edit: "Редактировать",
    editExaminee: "Редактировать обследуемого",
    editPsychLab: "Редактировать аккаунт клиента",
    editPsychLabLic: "Редактировать лицензию",
    addPsychLabLic: "Добавить лицензию",
    editPsychLabUser: "Редактировать аккаунт пользователя",
    examinationWithNoLawBase: "Обследование без правового основания",
    examinationWithLawBase: "Обследование с правовым основанием",
    exit: "Выход",
    generate: "Сгенерировать",
    generateAndPrint: "Сгенерировать и распечатать",
    go: "Перейти",
    goToDashboard: "Перейти в панель пользователя",
    login: "Войти",
    loginPsychLab: "Войти в аккаунт клиента",
    move: "Переместить",
    moveExaminee: "Переместить обследуемого",
    moveExaminees: "Переместить обследуемых",
    ok: "Ок",
    print: "Печать",
    printContract: "Распечатать контракт",
    save: "Сохранить",
    savePassword: "Сохранить пароль",
    savePin: "Сохранить ПИН",
    saveAndPrint: "Сохранить и распечатать",
    saveSettings: "Сохранить настройки",
    setPin: "Установить ПИН",
    search: "Поиск",
    setPassword: "Установить пароль",
    setNewPassword: "Установить новый пароль",
    sendLink: "Отправить ссылку",
    skipTests: "Пропустить тесты",
    understood: "Понятно",
    showActivities: "Показать",
    exportActivities: "Экспорт в файл XLS",
  },
  Common: {
    menu: {
      contact: "Помощь",
      dashboard: "Панель пользователя",
      folders: "Записи обследуемых",
      search: "Поиск",
      logout: "Выйти",
      settings: "Настройки",
      psychlabs: "Записи клиентов",
      breadcrumbsPsychLab: "Записи клиентов",
    },
    seo: {
      addPsychLab: "Добавить аккаунт клиента",
      addPsychLabLic: "Добавить лицензию",
      folders: "Записи обследуемых",
      examinationAssignment: "Назначение обследования с правовым основанием",
      setNewPassword: "Установить новый пароль",
      dashboard: "Panel użytkownika",
      editExaminee: "Edytuj badanego",
      helpdesk: "Pomoc",
      helpdeskDocuments: "Dokumenty pomocy",
      judgement: "Orzeczenie",
      login: "Logowanie",
      onboarding: "Onboarding",
      report: "Raport",
      resetPassword: "Resetowanie hasła",
      search: "Wyszukiwarka",
      settings: "Ustawienia",
      examinationCard: "Karta Badania",
    },
    warningPopup: {
      text: "Внесенные вами изменения не будут сохранены",
      header: "Вы уверены, что хотите выйти?",
      textSave: "Обязательные поля могут также находиться на других вкладках",
      headerSave: "Пожалуйста, заполните все обязательные поля!",
      promptLogout: "Вы будете автоматически выйдете из системы через PROMPT_TIME_MINUTES минуту из-за отсутствия активности."
    },
    logAdminAsUserPopup: {
      text: "Не удалось войти в аккаунт клиента",
      header: "Ошибка"
    },
    deleteExamineePopup: {
      text: "После удаления обследуемого восстановление данных будет невозможно",
      header: "Вы уверены, что хотите удалить обследуемого?"
    },
  },
  Contact: {
    header: "Техническая поддержка",
  },
  Cookies: {
    header: "Разрешение на использование cookies",
    consent: "Этот сайт использует cookies для обеспечения наилучшей работы этого приложения. Если вы согласны на хранение информации в cookies, нажмите кнопку СОГЛАСЕН. Если вы не согласны, вы не сможете использовать приложение и выполнять назначенные процедуры."
  },
  Dashboard: {
    header: "Недавно добавленные люди",
    footer: {
      lastLogin: "Последний вход",
      lastFailedLogin: "Последний неудачный вход",
      passwordExpired: "Срок действия вашего пароля истекает через",
      day: "день",
      days: "дней"
    },
    emptyState: {
      header: "Нет недавно добавленных обследуемых",
      info: "Чтобы увидеть список обследуемых, добавьте хотя бы одного."
    },
    kebabMenu: {
      print: "Распечатать контракт",
      profile: "Перейти к профилю обследуемого"
    }
  },
  AdminDashboard: {
    header: "Картотека клиентов",
    emptyState: {
      header: "Учетные записи клиентов еще не добавлены",
      info: "Чтобы увидеть список клиентов, добавьте хотя бы одну учетную запись."
    },
    emptyStateUsers: {
      header: "Учетные записи пользователей еще не добавлены",
      info: "Чтобы увидеть список пользователей, добавьте хотя бы одну учетную запись."
    },
    emptyStateLicense: {
      header: "Лицензии еще не добавлены",
      info: "Чтобы увидеть лицензию, добавьте её."
    },
    kebabMenu: {
      print: "Распечатать контракт",
      profile: "Перейти к профилю обследуемого"
    },
    PsychologicalLabsList: {
      labels: {
        name: "Название клиента",
        clientId: "ID клиента",
        status: "Статус",
        psychCnt: "Количество пользователей",
        createDate: "Дата создания",
        expireDate: "Срок действия учетной записи",
        details: "Подробности",
        usersCnt: "Количество пользователей",
        examineesCnt: "Количество обследуемых",
        examinationsCnt: "Количество обследований",
        taskPerPage: "Строк на странице:",
        all: "все",
        of: "из",
        moreThen: "больше чем"
      },
      labStatus: {
        enabled: "активный",
        disabled: "неактивный"
      }
    },
    Licence: {
      labels: {
        methodologyList: "Доступные методики",
        testsList: "Доступные тесты",
        licenseDate: "Дата выдачи лицензии",
        expireDate: "Срок действия лицензии"
      },
      deletePopup: {
        headerText: "Вы уверены, что хотите удалить лицензию?",
        contentText: "После удаления лицензии будет невозможно назначать обследования",
        confirmationText: "Да, я хочу удалить лицензию"
      }
    },
    PsychLab: {
      deletePopup: {
        headerText: "Вы уверены, что хотите удалить учетную запись клиента?",
        contentText: "После удаления учетной записи клиента будет невозможно восстановить данные",
        confirmationText: "Да, я хочу удалить учетную запись клиента"
      }
    },
    StatsList: {
      statFileName: "статистика_обследований.xlsx"
    },
    ActivitiesList: {
      labels: {
        date: "Дата",
        action: "Действие",
        executor: "Исполнитель",
        context: "Описание",
        status: "Статус",
        errorMsg: "Описание ошибки"
      },
      labStatus: {
        success: "успешно",
        error: "ошибка"
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "Вы уверены, что хотите удалить учетную запись пользователя?",
        contentText: "После удаления учетной записи пользователя её невозможно будет восстановить",
        confirmationText: "Да, я хочу удалить учетную запись пользователя"
      },
      labels: {
        firstName: "Имя",
        lastName: "Фамилия",
        email: "Email",
        examineeCnt: "Количество участников",
        examinationCnt: "Количество обследований",
        isActive: "Статус",
        isStaff: "Роль"
      },
      labStatus: {
        enabled: "активный",
        disabled: "неактивный",
        adminRole: "администратор",
        superUserRole: "супер администратор",
        userRole: "пользователь",
        userBaseRole: "регистратор"
      },
      toolTip: {
        edit: "редактировать",
        delete: "удалить"
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Добавить учетную запись клиента",
        edit: "Редактировать учетную запись клиента",
        addLicense: "Добавить лицензию",
        editLicense: "Редактировать лицензию",
        addUser: "Добавить учетную запись пользователя",
        editUser: "Редактировать учетную запись пользователя",
        dashboard: "Панель пользователя",
        psychLabs: "Картотека клиентов"
      },
      header: {
        add: "Добавить учетную запись клиента",
        edit: "Редактировать учетную запись клиента",
        addUser: "Добавить учетную запись пользователя",
        editUser: "Редактировать учетную запись пользователя",
        addLicense: "Добавить лицензию",
        editLicense: "Редактировать лицензию"
      },
      PsychLabView: {
        labels: {
          details: "Подробности",
          download: "Скачать",
          edit: "Редактировать",
          generate: "Сгенерировать",
          name: "Название теста",
          nameFile: "Имя файла",
          print: "Печать",
          result: "Результат",
          type: "Тип документа"
        },
        tabs: {
          psychLabData: "Данные клиента",
          license: "Лицензия",
          users: "Пользователи",
          activityLogs: "Журнал действий",
          activityLogsFile: "журнал_действий",
          activityId: "id",
          activityDate: "дата",
          activityAction: "название действия",
          activityStatus: "статус",
          activityContext: "описание",
          activityExecutor: "исполнитель",
          stats: "Статистика",
          theme: "Внешний вид"
        }
      }
    }
  },
  ExaminationAssignmentView: {
    header: "Назначить обследование",
    labels: {
      chooseExaminationType: "Выберите тип обследования",
      chooseLawBase: "Выберите правовое основание обследования",
      description: "Описание",
      legalBase: "Правовое основание обследования",
      reason: "Цель обследования",
      suggestedTestMethods: "ПРЕДЛАГАЕМЫЕ МЕТОДИКИ",
      methodologyTest2Safe: "TEST2SAFE - методика психологического тестирования для работников охраны и детективов",
      methodologyTest2Drive: "TEST2DRIVE - методика проведения обследований водителей в рамках транспортной психологии",
      methodologyTest2Arms: "TEST2ARMS - методика психологического тестирования, лицензируемая деятельность",
      methodologyTest2Work: "TEST2WORK - методика психологического тестирования в медицине труда"
    },
    popup: {
      header: "Некоторые тесты, рекомендованные для выбранной методики, были отключены.",
      content: "Вы уверены, что хотите пропустить эти тесты?",
      checkbox: "Запомнить выбор для всех обследуемых"
    },
    license: "Тест недоступен в приобретенной лицензии"
  },
  PsychologicalTests: {
    psychomotor: "Психомоторные тесты",
    cognitive: "Когнитивные тесты",
    personality: "Личностные тесты"
  },
  ExamineeList: {
    labels: {
      contract: "Контракт",
      examine: "Обследование",
      examineLastDate: "Дата последнего обследования",
      allFinishExam: "Назначено / завершено",
      firstName: "Имя",
      folder: "Папка",
      lastName: "Фамилия",
      peselSlashIdentifier: "PESEL / Идентификатор",
      profile: "Просмотр профиля",
      added: "Добавлено"

    },
  },
  ExaminationList: {
    withoutLegalBasis: "Обследование без правового основания",
    labels: {
      assign: "Назначено",
      assignDate: "Дата назначения",
      create: "Создано",
      createDate: "Дата создания",
      contract: "Контракт",
      documentation: "Документация",
      end: "Завершено",
      endDate: "Дата завершения",
      interview: "Интервью",
      identificator: "ID обследования",
      language: "Язык",
      nameArchiveExamination: "Название батареи",
      nameExamination: "Название обследования",
      start: "Начато",
      startDate: "Дата начала"
    },
    kebabMenu: {
      delete: "Удалить обследование"
    },
    deletePopup: {
      headerText: "Вы уверены, что хотите удалить обследование?",
      contentText: "После удаления обследования его невозможно будет восстановить",
      confirmationText: "Да, я хочу удалить обследование"
    },
    toolTip: {
      contract: "Сгенерировать контракт",
      interview: "Интервью",
      documentation: "Документация",
      delete: "Удалить"
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "Нет архивных обследований, назначенных этому участнику",
    },
  },
  ExaminationView: {
    header: "Документация",
    breadcrumbs: {
      archive: "Архивные обследования"
    },
    details: {
      createDate: "Создание обследования",
      endDate: "Завершение обследования",
      identificator: "ID обследования",
      language: "Язык",
      name: "Название обследования",
      startDate: "Дата начала обследования",
      type: "Название батареи"
    },
    protocols: {
      language: "Язык протоколов",
      testProtocol: "Протокол теста"
    },
    labels: {
      details: "Подробности",
      download: "Скачать",
      edit: "Редактировать",
      generate: "Сгенерировать",
      name: "Название теста",
      nameFile: "Имя файла",
      print: "Печать",
      result: "Результат",
      type: "Тип документа"
    },
    tabs: {
      documentation: "Документация",
      protocols: "Протоколы",
      reports: "Отчеты"
    },
    common: {
      unavailableInLanguageOption: "Недоступно в выбранной языковой версии."
    },
    aimsKind: {
      aimKind_1: "Психологическое заключение - детективы",
      aimKind_3: "Заключение - работник физической охраны",
      aimKind_8: "Апелляционное заключение - работник физической охраны",
      aimKind_9: "Заключение - разрешение на оружие",
      aimKind_10: "Заключение - лицензированная деятельность военного или полицейского назначения",
      aimKind_11: "Заключение - лицензированная деятельность гражданского назначения",
      aimKind_12: "Апелляционное заключение - лицензированная деятельность гражданского назначения",
      aimKind_13: "Заключение - медицина труда",
      aimKind_14: "Заключение - медицина труда горное дело и горноспасательные работы",
      aimKind_15: "Заключение - медицина труда лицензия машиниста",
      aimKind_16: "Заключение - водитель категории C1, C1+E, C, C+E, D1, D1+E, D и D+E или управление трамваем",
      aimKind_17: "Заключение - восстановление водительских прав категории AM, A1, A2, A, B1, B, B+E и T",
      aimKind_18: "Заключение - восстановление водительских прав психологические противопоказания",
      aimKind_19: "Заключение - продление прав категории C1, C1+E, C, C+E, D1, D1+E, D и D+E или управление трамваем",
      aimKind_20: "Заключение - послеаварийное обследование водителя",
      aimKind_21: "Заключение - обследование водителя после употребления алкоголя или подобных веществ",
      aimKind_22: "Заключение - обследование водителя после превышения 24 баллов",
      aimKind_23: "Заключение - обследование водителя после не менее 2 нарушений",
      aimKind_24: "Заключение - обследование водителя психологические противопоказания",
      aimKind_25: "Заключение - водитель спецтранспорта",
      aimKind_26: "Заключение - послеаварийное обследование водителя (направление старосты)",
      aimKind_27: "Заключение - выполнение обязанностей инструктора",
      aimKind_28: "Заключение - выполнение обязанностей экзаменатора",
      aimKind_29: "Заключение - водитель-учащийся или кандидат в среднюю школу категории C или C+E",
      aimKind_30: "Заключение - водитель-учащийся или кандидат на профессиональный курс категории C или C+E",
      aimKind_31: "Заключение - профессиональный водитель по направлению работодателя",
      aimKind_32: "Заключение - самозанятый профессиональный водитель",
    },
  },
  ExamineeView: {
    anonymity: "Badany anonimowy",
    userData: {
      defaultAnonFirstName: "Badany",
      defaultAnonLastName: "Anonimowy",
      pesel: "PESEL",
      sex: "Płeć",
      birthDate: "Data urodzenia",
      address: "Adres",
      education: "Wykształcenie",
      drivingLicense: "Kategoria prawa jazdy",
      identityCard: "Dokument tożsamości",
      serialNumber: "Seria i numer",
      identificator: "Identyfikator",
      drivingLicenseIssuer: "Podmiot wydający prawo jazdy",
      examineName: "Imię i nazwisko",
    },
    tabs: {
      archive: "Badania archiwalne",
      data: "Dane badanego",
      examination: "Badania",
    },
    kebabMenu: {
      edit: "Edytuj dane badanego",
      delete: "Usuń badanego",
    },
    emptyState: {
      header: "Brak badań przypisanych do danego badanego",
      info: "Aby zobaczyć listę badań, przydziel przynajmniej jedno badanie.",
    },
    translations: {
      passport: "paszport",
      id_card: "dowód osobisty",
      male: "mężczyzna",
      female: "kobieta",
      primary: "podstawowe",
      lowerSecondary: "gimnazjalne",
      basicVocational: "zasadnicze zawodowe",
      basicIndustry: "zasadnicze branżowe",
      secondaryIndustry: "średnie branżowe",
      secondary: "średnie",
      higher: "wyższe",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Dodaj badanego",
      edit: "Edytuj badanego",
      dashboard: "Panel użytkownika",
      directories: "Kartoteka badanych",
    },
    header: {
      add: "Dodaj osobę badaną",
      edit: "Edytuj dane osoby badanej",
    },
  },
  Forms: {
    fields: {
      address: "Ulica i numer domu",
      additionalInfo: "Informacje dodatkowe",
      appealAuthority: "Nazwa instytucji",
      appealDeadline: "Data",
      birthDate: "Data urodzenia",
      city: "Miasto",
      country: "Kraj",
      diffrentIdentificationNumber: "Inny identyfikator",
      directory: "Folder",
      documentDate: "Data wystawienia",
      drivingLicense: "Kategoria prawa jazdy",
      drivingLicenseIssuer: "Podmiot wydający prawo jazdy",
      education: "Wykształcenie",
      identificationNumber: "PESEL",
      identityDocumentType: "Dokument tożsamości",
      identityDocumentNumber: "Seria i numer",
      interval: "Wybierz przedział ufności",
      judgmentNumber: "Numer orzeczenia",
      languageReport: "Wybierz język raportu",
      legalBasis: "Podstawa prawna badania",
      months: "miesiące",
      name: "Imię",
      nextExamination: "Data kolejnego badania",
      postalCode: "Kod pocztowy",
      recipient: "Wybierz odbiorcę",
      sex: "Płeć",
      standard: "Wybierz normę",
      surname: "Nazwisko",
      years: "Lata",
      year: "Rok",
      examineeRegistryId: "Numer w rejestrze osób badanych",
      age: "Wiek",
      date: "Data",
      institutionName: "Nazwa instytucji",
    },
    options: {
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Tak",
        false: "Nie",
      },
      education: {
        primary: "Podstawowe",
        lowerSecondary: "Gimnazjalne",
        secondary: "Średnie",
        basicVocational: "Zasadnicze zawodowe",
        basicIndustry: "Zasadnicze branżowe",
        secondaryIndustry: "Średnie branżowe",
        higher: "Wyższe",
      },
      identityDocument: {
        idCard: "Dowód osobisty",
        passport: "Paszport",
      },
      sex: {
        female: "Kobieta",
        male: "Mężczyzna",
      },
      country: {
        poland: "Polska",
        austria: "Austria",
        bulgaria: "Bułgaria",
        croatia: "Chorwacja",
        cyprus: "Cypr",
        czechRepublic: "Republika Czeska",
        denmark: "Dania",
        estonia: "Estonia",
        finland: "Finlandia",
        france: "Francja",
        greece: "Grecja",
        spain: "Hiszpania",
        netherlands: "Holandia",
        ireland: "Irlandia",
        lithuania: "Litwa",
        luxembourg: "Luksemburg",
        latvia: "Łotwa",
        malta: "Malta",
        germany: "Niemcy",
        portugal: "Portugalia",
        romania: "Rumunia",
        slovakia: "Słowacja",
        slovenia: "Słowenia",
        sweden: "Szwecja",
        hungary: "Węgry",
        italy: "Włochy",
        afghanistan: "Afganistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Arabia Saudyjska",
        argentina: "Argentyna",
        armenia: "Armenia",
        azerbaijan: "Azerbejdżan",
        bangladesh: "Bangladesz",
        belarus: "Białoruś",
        bolivia: "Boliwia",
        bosniaAndHerzegovina: "Bośnia i Hercegowina",
        brazil: "Brazylia",
        chile: "Chile",
        china: "Chiny",
        chad: "Chad",
        montenegro: "Czarnogóra",
        democraticRepublicOfTheCongo: "Demokratyczna Republika Konga",
        dominicanRepublic: "Republika Dominikany",
        ecuador: "Ekwador",
        ethiopia: "Etiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Gruzja",
        guatemala: "Gwatemala",
        honduras: "Honduras",
        india: "Indie",
        indonesia: "Indonezja",
        iran: "Iran",
        iceland: "Islandia",
        israel: "Izrael",
        jamaica: "Jamajka",
        japan: "Japonia",
        yemen: "Jemen",
        jordan: "Jordania",
        cambodia: "Kambodża",
        cameroon: "Kamerun",
        qatar: "Katar",
        kazakhstan: "Kazachstan",
        congo: "Kongo",
        southKorea: "Korea Południowa",
        laos: "Laos",
        northernMacedonia: "Północna Macedonia",
        madagascar: "Madagaskar",
        malaysia: "Malezja",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Meksyk",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambik",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nikaragua",
        norway: "Norwegia",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paragwaj",
        peru: "Peru",
        centralAfricanRepublic: "Republika Środkowoafrykańska",
        russia: "Rosja",
        rwanda: "Rwanda",
        elSalvador: "Salwador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somali",
        unitedStatesOfAmerica: "Stany Zjednoczone Ameryki",
        sudan: "Sudan",
        southSudan: "Południowy Sudan",
        switzerland: "Szwajcaria",
        tajikistan: "Tadżykistan",
        thailand: "Tajlandia",
        turkey: "Turcja",
        uganda: "Uganda",
        ukraine: "Ukraina",
        uruguay: "Urugwaj",
        venezuela: "Wenezuela",
        unitedKingdom: "Zjednoczone Królestwo",
        vietnam: "Wietnam",
        zambia: "Zambia",
        unitedArabEmirates: "Zjednoczone Emiraty Arabskie",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Osoba badana",
        psychologist: "Psycholog",
        specialist: "Specjalista",
      },
      languagesDocumentation: {
        pl: "polski",
        en: "angielski",
      },
    },
    extraFields: "Dane uzupełniające",
    requiredFields: "Dane obowiązkowe",
    anonymous: "Badanie anonimowe",
    snackbar: {
      peselLength: "PESEL musi składać się z 11 cyfr",
      peselNumber: "PESEL może składać się tylko z liczb",
      required: "Należy uzupełnić wszystkie pola obowiązkowe.",
    },
  },
  FormValidation: {
    max: "Nazwa nie może mieć więcej niż 128 znaków",
    required: "To pole jest obowiązkowe",
    Pin: {
      length: "Musi zawierać 4 cyfry",
    },
    Password: {
      length: "Minimum 8 znaków",
      characters:
        "Musi zawierać wielką literę, małą literę, znak specjalny oraz cyfrę",
    },
  },
  Helpdesk: {
    header: "Pomoc",
    subheader: "Help desk",
    help: "Pomoc zdalna",
    contact: "Kontakt",
    documents: {
      header: "Dokumenty pomocy",
      labels: {
        download: "Pobierz",
        name: "Nazwa dokumentu",
      },
    },
  },
  Interview: {
    header: "Wywiad",
    inputAdditionalInformation: "Wpisz dodatkowe informacje",
    inputHoursOfWork: "Wpisz ilość godzin pracy w ostatniej dobie",
    years: "Lata",
    months: "Miesiące",
    hours: "Godziny",
    tabs: {
      currentPsychophysicalState: "Sytuacja zdrowotna",
      socialAndFamilySituation: "Sytuacja społeczna i rodzinna",
      observation: "Obserwacja",
      drivingVehicles: "Kierowanie pojazdami",
      occupationalMedicine: "Medycyna pracy",
      securityAndDetectives: "Detektywi i pracownicy ochrony",
      licensedActivities: "Działalność koncesjonowana",
    },
    questions: {
      questionAnswers: {
        yes: "tak",
        no: "nie",
      },
      questionTexts: {
        when: "Kiedy ?",
        sinceWhen: "Od kiedy ?",
        forHowLong: "Do kiedy ?",
        why: "Z jakiego powodu ?",
        whatAreTheConsequences: "Jakie były skutki zdrowotne ?",
        describeTheCircumStancesOfUsage: "Opisz okoliczności tego użycia",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Aktualny stan psychofizyczny",
            criticalEvents: "Zdarzenia krytyczne",
          },
          questionExtendedTexts: {
            commonText: "Opisz okoliczności tego zdarzenia/sytuacji",
          },
          questionTexts: {
            question1:
              "Podstawowe cele wykorzystania broni:",
            question2:
              "Czy kiedykolwiek miało miejsce użycie broni {...} lub innych środków przymusu bezpośredniego?",
            question3: "Jak często miało miejsce użycie broni w ciągu ostatnich 5 lat?",
            question4: "Stanowisko / wykonywane czynności",
            question5: "Czynniki szkodliwe/uciążliwe występujące na stałe w miejscu pracy",
            question6: "Staż pracy ogółem",
            question7:
              "Staż pracy na obecnym stanowisku" +
              " czy pojawiło się kiedykolwiek rozpoznanie następujących chorób:",
            question7a:
              "jeżdżę najczęściej nocą",
            question7b:
              "jeżdżę zwykle w ciągu dnia",
            question7c:
              "jeżdżę zarówno w ciągu dnia jak i nocą",
            question7d: "d. cukrzycy",
            question7e: "e. epilepsji",
            question7f:
              "f. chorób psychicznych (depresja, nerwica, {...} leczenie psychiatryczne (dopytać o leczenie psychiatryczne członka bliskiej rodziny)" +
              " leczenie psychiatryczne (dopytać o leczenie psychiatryczne członka bliskiej rodziny)",
            question8:
              "Stwierdzono chorobę zawodową" +
              " ma świadomość działań ubocznych i dopytać o inne leki wpływające na funkcje psychiczne)",
            question9: "Przyznano świadczenie rentowe",
            question10: "Osoba badana uległa wypadkowi w pracy",
            question10a: "Kiedy? Jakie były skutki zdrowotne?",
            question10b: "b. lekarza psychiatry",
            question10c: "c. neurologa",
            question11: "Orzeczono niepełnosprawność",
            question12: "Staż w prowadzeniu pojazdów ogólnie",
            question13: "Liczba rocznie przejechanych kilometrów",
            question14:
              "Liczba godzin spędzanych na prowadzeniu samochodu",
            question15:
              "Orzeczenie niezdolności do prowadzenia pojazdów" +
              " roztargnienie lub problemy z pamięcią lub orientacją",
          },
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1:
              "Poważny konflikt lub problem związany z pracą " +
              "(np. wypadek w pracy, nagana, sąd pracy, zwolnienie dyscyplinarne, mobbing)",
            question2:
              "Problemy na etapie kształcenia " +
              "(np. powtarzanie klasy, wyrzucenie ze szkoły, zawieszenie w prawach ucznia)",
            question3:
              "Poważny konflikt rodzinny lub problemy w sytuacjach prywatnych " +
              "(np. sprawy sądowe, udział w bójkach, można dopytać o status związku, dzieci) ",
            question4:
              "Sytuacja bezpośredniego zagrożenia życia (np. pożar, powódź i inne katastrofy)",
            question5:
              "Doznanie aktu przemocy fizycznej na własnej osobie (np. napaść, kradzież, pobicie)",
            question6:
              "Zaangażowanie w działania organizacji społecznych (np. wolontariat)",
            question7: "Karalność (np. otrzymanie mandatu, wyrok sądu) ",
          },
        },
        observation: {
          questionTexts: {
            question1: "Kontakt z rozmówcą",
            question2: "Język i retoryka wypowiedzi",
            question3: "Nastrój i nastawienie",
            question4: "Opanowanie i kontrola zachowań i emocji",
          },
          questionAnswers: {
            question1a:
              "do celów zawodowych (detektyw, pracownik ochrony)" +
              " zachowuje się pewnie i swobodnie, nie pozwala się wytrącić z równowagi czy zniecierpliwić," +
              " zachowuje się spontanicznie i naturalnie. ",
            question1b:
              "do celów łowieckich" +
              " choć widoczne są oznaki pewnego zdenerwowania. ",
            question1c:
              "do celów sportowych" +
              " małe bodźce powodują wytrącenie z równowagi, widoczne są wyraźne oznaki zdenerwowania i zniecierpliwienia. ",
            question2a:
              "Formułuje wypowiedzi używając poprawnego języka, {...}  buduje jasne, czytelne zdania, utrzymuje logiczną strukturę wypowiedzi, {...} wykorzystuje bogaty zasób słownictwa." +
              " buduje jasne, czytelne zdania, utrzymuje logiczną strukturę wypowiedzi," +
              " wykorzystuje bogaty zasób słownictwa.  ",
            question2b:
              "Formułuje poprawnie wypowiedzi,{...} generalnie utrzymuje ich logiczną strukturę; zasób słów przeciętny, ale poprawny." +
              "generalnie utrzymuje ich logiczną strukturę; zasób słów przeciętny, ale poprawny. ",
            question2c:
              "Formułuje krótkie, zdawkowe wypowiedzi, często niespójne lub niejasne; {...} używa niepoprawnych zwrotów i sformułowań; zdania są bardzo proste, {...} słownictwo bardzo ubogie, występują błędy gramatyczne." +
              " używa niepoprawnych zwrotów i sformułowań; zdania są bardzo proste," +
              " słownictwo bardzo ubogie, występują błędy gramatyczne. ",
            question3a:
              "mniej niż 3 000 km rocznie",
            question3b:
              "między 3 000 – 15 000 km rocznie",
            question3c:
              "między 15 000 - 70 000 km rocznie" +
              " wyraźna skłonność do pesymistycznego odbioru świata; okazywanie niezadowolenia. ",
            question4a:
              "Udział w wypadku w miejscu pracy" +
              " adekwatnie dostosowuje się do pojawiających się okoliczności. ",
            question4b:
              "Jakie były przyczyny, konsekwencje wypadku - czy nastąpiło uszkodzenie mienia, zdrowia?" +
              " najczęściej adekwatnie reaguje na pojawiające się okoliczności. ",
            question4c:
              "między 15 - 40 godzin tygodniowo" +
              " pojawiają się nieadekwatne zachowania lub emocje. ",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Charakterystyka przejeżdżanych tras",
          },
          questionTexts: {
            question1: "Staż w prowadzeniu pojazdów ogólnie",
            question2: "Staż w prowadzeniu pojazdów na stanowisku kierowcy",
            question3: "Liczba rocznie przejechanych kilometrów",
            question4: "Liczba godzin spędzanych na prowadzeniu samochodu",
            question5: "Długość tras ",
            question6: "Różnorodność",
            question7: "Pory dnia ",
            question8: "Zmiennik",
            question9: "Obszar",
            question10: "Zatrzymanie lub odebranie prawa jazdy",
            question11: "Orzeczenie niezdolności do prowadzenia pojazdów",
            question12: "Kara za wykroczenie lub przestępstwo drogowe",
            question13: "Udział jako kierowca w wypadku samochodowym",
            question14: "Uczestnictwo jako kierowca w kolizji drogowej",
            question15: "Posiadane dodatkowe uprawnienia i licencje",
          },
          questionAnswers: {
            question3a: "mniej niż 3 000 km rocznie",
            question3b: "między 3 000 – 15 000 km rocznie",
            question3c: "między 15 000 - 70 000 km rocznie",
            question3d: "powyżej 70 000 km rocznie",
            question3e: "trudno powiedzieć",

            question4a: "mniej niż 5 godzin tygodniowo",
            question4b: "między 5 - 15 godzin tygodniowo",
            question4c: "między 15 - 40 godzin tygodniowo",
            question4d: "powyżej 40 godzin tygodniowo",
            question4e: "trudno powiedzieć",

            question5a: "Praca monotonna",
            question5b: "Stały duży dopływ informacji",
            question5c: "Wysoki poziom hałasu",

            question6a: "jeżdżę zazwyczaj na stałych trasach",
            question6b: "jeżdżę najczęściej na różnych trasach",

            question7a: "jeżdżę najczęściej nocą",
            question7b: "jeżdżę zwykle w ciągu dnia",
            question7c: "jeżdżę zarówno w ciągu dnia jak i nocą",

            question8a: "Kiedy? Z jakiego powodu?",
            question8b: "jeżdżę najczęściej ze zmiennikiem",

            question9a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question9b: "jeżdżę głownie poza miastem",

            question13a: "mniej niż 3 000 km rocznie",
            question13b: "między 3 000 – 15 000 km rocznie",
            question13c: "między 15 000 - 70 000 km rocznie",

            question14a: "mniej niż 5 godzin tygodniowo",
            question14b: "między 5 - 15 godzin tygodniowo",
            question14c: "między 15 - 40 godzin tygodniowo",

            question15a: "przewóz materiałów niebezpiecznych",
            question15b: "przewóz osób",
            question15c: "instruktor prawa jazdy",
            question15d: "egzaminator prawa jazdy",
            question15e: "brak",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Informacje dotyczące pracy zawodowej",
            workCourseInformation: "Przebieg pracy zawodowej",
            companyCarDrivers: "Kierowcy samochodów służbowych",
            workingAtHeights: "Praca na wysokości",
            forkliftOperators: "Operatorzy wózków widłowych",
            minersAndMinerLifeguards: "Górnicy i ratownicy górniczy",
            trainDriver: "Maszyniści",
          },
          questionTexts: {
            question1: "Zawód wyuczony",
            question2: "Zawód wykonywany",
            question3: "Miejsce pracy / szkoła",
            question4: "Stanowisko / wykonywane czynności",
            question5:
              "Czynniki szkodliwe/uciążliwe występujące na stałe w miejscu pracy",
            question5f: "Czynniki chemiczne",
            question5g: "Inne",
            question6: "Staż pracy ogółem",
            question7: "Staż pracy na obecnym stanowisku",
            question8: "Stwierdzono chorobę zawodową",
            question8a: "Kiedy? Z jakiego powodu?",
            question9: "Przyznano świadczenie rentowe",
            question9a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question10: "Osoba badana uległa wypadkowi w pracy",
            question10a: "Kiedy? Jakie były skutki zdrowotne?",
            question11: "Orzeczono niepełnosprawność",
            question11a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question12: "Staż w prowadzeniu pojazdów ogólnie",
            question13: "Liczba rocznie przejechanych kilometrów",
            question14: "Liczba godzin spędzanych na prowadzeniu samochodu",
            question15: "Orzeczenie niezdolności do prowadzenia pojazdów",
            question16: "Zatrzymanie lub odebranie prawa jazdy",
            question17: "Kara za wykroczenie lub przestępstwo drogowe",
            question18: "Udział jako kierowca w wypadku samochodowym",
            question19: "Uczestnictwo jako kierowca w kolizji drogowej",
            question20:
              "Posiadanie uprawnień do wykonywania pracy na wysokości",
            question21:
              "Wykonywanie prac na wysokości (jeśli tak, dopytać, ile metrów nad ziemią)",
            question22:
              "Subiektywne odczuwanie lęku podczas przebywania na wysokości",
            question23: "Wypadek podczas pracy na wysokości",
            question23a: "Czy nastąpiło uszkodzenie mienia lub zdrowia?",
            question24:
              "Posiadanie uprawnień do wykonywania pracy operatora wózka widłowego",
            question25:
              "Wykonywanie prac związanych z obsługą i prowadzeniem wózka widłowego",
            question26:
              "Wypadek podczas obsługi lub prowadzenia wózka widłowego",
            question26a: "Czy nastąpiło uszkodzenie mienia lub zdrowia?",
          },
          questionAnswers: {
            question5a: "Praca monotonna",
            question5b: "Stały duży dopływ informacji",
            question5c: "Wysoki poziom hałasu",
            question5d:
              "Praca z dużym obciążeniem psychicznym (odpowiedzialność i decyzyjność)",
            question5e: "Promieniowanie jonizujące",
            question5f: "Czynniki chemiczne",
            question5g: "Inne",

            question13a: "mniej niż 3 000 km rocznie",
            question13b: "między 3 000 – 15 000 km rocznie",
            question13c: "między 15 000 - 70 000 km rocznie",
            question13d: "powyżej 70 000 km rocznie",
            question13e: "trudno powiedzieć",

            question14a: "mniej niż 5 godzin tygodniowo",
            question14b: "między 5 - 15 godzin tygodniowo",
            question14c: "między 15 - 40 godzin tygodniowo",
            question14d: "powyżej 40 godzin tygodniowo",
            question14e: "trudno powiedzieć",

            question18a: "jako sprawca/współsprawca",
            question18b: "jako poszkodowany",
            question18c: "nie dotyczy",

            question19a: "jako sprawca/współsprawca",
            question19b: "jako poszkodowany",
            question19c: "nie dotyczy",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1:
              "Staż pracy ogółem w zakładzie pracy zajmującym " +
              "się działalnością koncesjonowaną",
            question2: "Stanowisko/ wykonywane czynności",
            question3:
              "Staż pracy na obecnym stanowisku w " +
              "zakładzie pracy zajmującym się działalnością koncesjonowaną",
            question4a: "Udział w wypadku w miejscu pracy",
            question4b:
              "Jakie były przyczyny, konsekwencje wypadku - czy nastąpiło uszkodzenie mienia, zdrowia?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Podstawowe cele wykorzystania broni:",
            question1g: "Jakie ?",
            question2:
              "Czy kiedykolwiek miało miejsce użycie broni " +
              "lub innych środków przymusu bezpośredniego?",
            question3:
              "Jak często miało miejsce użycie broni w ciągu ostatnich 5 lat?",
          },
          questionAnswers: {
            question1a: "do celów zawodowych (detektyw, pracownik ochrony)",
            question1b: "do celów łowieckich",
            question1c: "do celów sportowych",
            question1d: "do użytku osobistego",
            question1e: "do celów muzealnych, pamiątkowych, naukowych",
            question1f: "inne",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Wyszukaj badanych",
    placeholderMobile: "Wyszukaj",
    placeholderDesktop:
      "Imię, nazwisko, PESEL/Identyfikator, data przydzielenia badania (RRRR-MM-DD)",
    noResults: "Brak wyników wyszukiwania",
    noResultsForFilter:
      "Nie znaleziono żadnych badanych spełniających wprowadzone wymagania.",
    searchForExaminees: "Wyszukaj badanych",
    enterFilter:
      "Wyszukiwać możesz po imieniu, nazwisku, numerze PESEL/Identyfikator {...} lub po dacie przydzielenia badania  {...} w formacie RRRR-MM-DD.",
  },
  Snackbars: {
    createExamineeSuccess: "Pomyślne dodano badanego",
    editExamineeSuccess: "Pomyślne edytowano badanego",
    failedRequest: "Nieudane zapytanie",
    movingExamineesSuccess: "Przeniesienie do innego folderu powiodło się",
    passwordChangeSuccess: "Zmiana hasła powiodła się",
    pinChangeSuccess: "Zmiana PINu powiodła się",
    success: "Akcja powiodła się",
    unrecognizedError: "Nierozpoznany błąd, sprawdź połączenie z internetem",
    createExaminationSuccess: "Poprawnie przydzielono badanie",
    deleteExaminationSuccess: "Poprawnie usunięto badanie",
  },
  UserSettings: {
    header: "Ustawienia konta",
    headerChangePassword: "Zmiana hasła",
    headerChangePin: "Zmiana PIN",
    setNewPin: "Ustaw nowy PIN",
    pinInfo:
      "Wprowadź PIN jaki będziesz wykorzystywać do logowania do konsolety psychologa.",
    headerMobile: "Ustawienia",
    facilityInfo: "Dane pracowni",
    userInfo: "Dane użytkownika",
    passwordChange: "Zmiana hasła",
    passwordExpiry: "Twoje hasło wygasa:",
    pinChange: "Zmień pin",
    infoSubHeaders: {
      labName: "Nazwa",
      address: "Adres",
      cin: "Numer licencji",
      tin: "NIP",
      firstName: "Imię",
      lastName: "Nazwisko",
      email: "Email",
      licenseNumber: "Numer licencji",
    },
    helpdeskMsgUser:
      "Jeśli chcesz zmienić dane użytkownika to zadzwoń na numer",
    helpdeskMsgLab: "Jeśli chcesz zmienić dane pracowni to zadzwoń na numer",
  },
  Backend: {
    User_activation_code_has_already_been_used:
      "Kod aktywacyjny został już użyty.",
    User_activation_code_does_not_exist: "Podany kod aktywacyjny nie istnieje.",
    User_with_given_id_does_not_exist: "Użytkownik z danym id nie istnieje.",
    Token_not_recognized: "Token nie został rozpoznany.",
    Unrecognized_token_Please_regenerate_password_reset_link:
      "Nierozpoznany token, proszę wygenerować nowy link do resetowania hasła.",
    Invalid_token_Please_regenerate_password_reset_link:
      "Nieprawidłowy token, proszę wygenerować nowy link do resetowania hasła.",
    Validation_failed: "Walidacja wprowadzonych danych nieudana.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee:
      "Nie udało się powiązać placówki użytkownika z badanym.",
    Could_not_bind_users_PsychologicalLab_with_the_directory:
      "Nie udało się powiązać placówki użytkownika z folderem.",
    The_password_cannot_be_the_same_as_the_previous_one:
      "Hasło nie może być takie samo jak poprzednie.",
    Incorrect_credentials: "Błędne hasło.",
    Unrecognized_email:
      "Adres email nie został rozpoznany lub konto nie zostało aktywowane.",
    Directory_has_related_Examinees:
      "Brak możliwości usunięcia Folderu Folder ma powiązanych badanych.",
    Unable_to_delete_default_directory:
      "Brak możliwości usunięcia Folderu Domyślnego.",
    The_new_pin_cannot_be_the_same_as_the_previous_one:
      "Nowy PIN nie może być taki sam jak poprzedni.",
    Directory_with_given_name_already_exists:
      "Folder z daną nazwą już istnieje.",
    Examination_Card_does_not_have_a_defined_NormSet:
      "Karta badania nie posiada przypisanej normy badania.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab:
      "Badany o podanym identyfikatorze już istnieje.",
    The_Examinee_has_related_Examinations:
      "Brak możliwości usunięcia Badanego Badany ma powiązane badania.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data:
      "Nierozpoznany błąd pobierania danych archiwalnych.",
    Legacy_data_instance_not_found: "Nie znaleziono danych archiwalnych.",
    Legacy_data_validation_failed: "Błąd walidacji danych archiwalnych.",
    Unable_to_parse_Examination_Aim: "Błąd walidacji Celu Badania.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data:
      "Błąd walicaji Przebiegu Badania - Medycyna Pracy",
    Ongoing_examination: "Brak możliwości usunięcia Badnia. Badanie w toku.",
  },
  Onboarding: {
    errorText:
      "Podczas ustawiania hasła nastąpił błąd, spróbuj ponownie później.",

    successTitle: "Hasło oraz PIN ustawione",
    success:
      "Twoje hasło oraz PIN zostały zapisane. Możesz teraz pierwszy raz zalogować się do aplikacji używając swojego adresu email oraz hasła.",
    setNewPasswordInfo:
      "Wprowadź hasło jakie będziesz wykorzystywać do logowania do systemu.",
    setPasswordFormLabel: "Ustaw hasło",
    setPinInfo:
      "Wprowadź PIN jaki będziesz wykorzystywać do logowania do konsolety psychologa.",
    setPinFormLabel: "Ustaw swój PIN",
  },
  ResetPassword: {
    formHeader: "Resetuj Hasło",
    resetInfo:
      "Wprowadź adres email swojego konta, aby otrzymać link do resetowania hasła.",
    resetEmailSentPt1: "Na adres e-mail:",
    resetEmailSentPt2:
      "została wysłana wiadomość zawierająca instrukcje dotyczące procesu resetowania hasła.",
    errorText:
      "Podczas resetowania hasła nastąpił błąd, spróbuj ponownie później.",
    successTitle: "Zapisano Hasło",
    success:
      "Twoje hasło zostało zmienione. Możesz teraz zalogować się do aplikacji używając swojego adresu email oraz nowego hasła.",
    setNewPassword: "Ustaw nowe hasło",
    setNewPasswordInfo:
      "Wprowadź hasło jakie będziesz wykorzystywać do logowania do systemu.",
  },
  FormLabels: {
    email: "E-mail",
    password: "Hasło",
    currentPassword: "Obecne hasło",
    newPassword: "Nowe hasło",
    pin: "PIN",
  },
  FormValidationErrors: {
    required: "To pole jest wymagane",
    email: "Wprowadzona wartość nie jest adresem email",
  },
  Login: {
    header: "LOGOWANIE",
    error: "Błędny e-mail lub hasło.",
    warning: "CAPS LOCK jest włączony",
    reset: "Zresetuj hasło",
    Instance_configured_incorrectly:
      "Błąd konfiguracji systemu. Zgłoś problem pomocy technicznej.",
    No_recaptcha_token_provided:
      "Błąd recaptcha. Zgłoś problem pomocy technicznej.",
    Validation_failed: "Błędny e-mail lub hasło.",
    Captcha_error: "Błąd wypełaniania captcha. Spróbuj ponownie.",
  },
  Directory: {
    defaultFolderName: "Folder domyślny",
    header: "Karoteka badanych",
    popup: {
      create: "Podaj nazwę folderu",
      edit: "Zmień nazwę folderu",
    },
    emptyState: {
      header: "Brak badanych w wybranym folderze",
      info: "Aby zobaczyć listę badanych w folderze dodaj do niego przynajmniej jednego badanego",
    },
    labels: {
      name: "Nazwa folderu",
      owner: "Właściciel",
      examineeCount: "Osoby",
    },
    kebabMenu: {
      addExaminee: "Dodaj badanego",
      addDirectory: "Dodaj folder",
      edit: "Edytuj nazwę folderu",
      delete: "Usuń folder",
    },
    popups: {
      delete: {
        confirmationHeader: "Czy na pewno chcesz usunąć folder?",
        confirmationText:
          "Po usunięciu folderu nie będzie możliwości przywrócenia go.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Przenieś badanych",
    labels: {
      firstName: "Imię",
      lastName: "Nazwisko",
      peselSlashIdentifier: "PESEL / Identyfikator",
      lastModification: "Ostatnia aktywność",
    },
    kebabMenu: {
      edit: "Edytuj dane badanego",
      move: "Przenieś badanego",
      delete: "Usuń badanego",
    },
    breadcrumbs: "Kartoteka badanych",
  },
  Pagination: {
    outOf: "z",
  },
  PasswordExpired: {
    popup: {
      header: "Upłynęła ważność hasła",
      text: "W celu ustawienia nowego hasła zostaniesz przeniesiony do procesu ustawienia nowego hasła.",
    },
    form: {
      header: "Ustaw nowe hasło",
      text: "Wprowadź hasło jakie będziesz wykorzystywać do logowania do systemu.",
    },
    success: {
      header: "Zapisano hasło",
      text: "Twoje hasło zostało zmienione. Możesz teraz przejść do aplikacji.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Pomyślnie zapisano dokument",
    },
    names: {
      ExaminationCard: "Karta badania",
      ExaminationCardTEST2WORK: "Karta badania TEST2WORK",
      ExaminationCardTEST2SAFE: "Karta badania TEST2SAFE",
      ExaminationCardTEST2ARMS: "Karta badania TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Karta badania TEST2DRIVE",
      PsychologicalConsultation: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2WORK: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2SAFE: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2ARMS: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2DRIVE: "Konsultacja psychologiczna",
      PsychologicalOpinion: "Opinia psychologiczna",
      PsychologicalOpinionTEST2WORK: "Opinia psychologiczna",
      PsychologicalOpinionTEST2SAFE: "Opinia psychologiczna - detektywi",
      PsychologicalOpinionTEST2ARMS: "Opinia psychologiczna",
      PsychologicalOpinionTEST2DRIVE: "Opinia psychologiczna",
      CivilExplosivesJudgment: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2WORK: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2SAFE: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2ARMS: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2DRIVE: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      DriverJudgment: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2WORK: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2SAFE: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2ARMS: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2DRIVE: "Orzeczenie kierowanie pojazdami",
      DrivingExaminerJudgment: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2WORK: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2SAFE: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2ARMS: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2DRIVE: "Orzeczenie egzaminator jazdy",
      DrivingInstructorJudgment: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2WORK: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2SAFE: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2ARMS: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2DRIVE: "Orzeczenie instruktor jazdy",
      DrivingTechniqueInstructorJudgment: "Orzeczenie instruktor techniki jazdy",
      EmergencyVehicleDriverJudgment: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Orzeczenie pojazdy uprzywilejowane",
      FirearmLicenseJudgment: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2WORK: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2SAFE: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2ARMS: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2DRIVE: "Orzeczenie pozwolenie na broń",
      MilitaryExplosivesJudgment: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2WORK: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2SAFE: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2ARMS: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Orzeczenie broń i materiały policyjne lub wojskowe",
      OccupationalMedicineJudgmentTEST2WORK: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2SAFE: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2ARMS: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2DRIVE: "Orzeczenie medycyna pracy",
      PhysicalProtectionJudgment: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2WORK: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2SAFE: "Orzeczenie - pracownik ochrony fizycznej",
      PhysicalProtectionJudgmentTEST2ARMS: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2DRIVE: "Orzeczenie licencja ochroniarza",
      ProfessionalDriverJudgment: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2WORK: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2SAFE: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2ARMS: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2DRIVE: "Orzeczenie stanowisko kierowcy",
    },
    emptyState: {
      header: "Dane z wywiadu bezpośredniego i obserwacji osoby badanej",
    },
    report: {
      details: {
        assignDate: "Data przydzielenia badania",
        endDate: "Data zakończenia badania",
        firstName: "Imię",

        PESEL: "PESEL",
        identificationNumber: "Identyfikator",
        idExamination: "ID badania",
        lastName: "Nazwisko",
        name: "Nazwa badania",
        status: "Status badania",
      },
      statusExamination: {
        skip: "Pominięto lub przerwano niektóre testy",
      },
      header: {
        language: "Wybierz język raportu",
        standard: "Wybierz normę",
        recipient: "Wybierz odbiorcę",
        interval: "Wybierz przedział ufności",
        reportInterpretation: "Raport interpretacyjny",
        reportDiagnostic: "Raport diagnostyczny",
      },
    },
    consultation: {
      breadcrumbs: "Opinia psychologiczna",
      examineeData: "Dane badanego",
      statement: "Stwierdzam*",
    },
    opinion: {
      breadcrumbs: "Opinia psychologiczna",
      breadcrumbsAppeal: "Opinia psychologiczna odwoławcza",
      examineeData: "Dane badanego",
      statement:
        "NA PODSTAWIE PRZEPROWADZONEGO BADANIA PSYCHOLOGICZNEGO STWIERDZAM",
    },
    judgment: {
      isAppealTitle: "Treść orzeczenia",
      appeal: "Od niniejszego orzeczenia służy prawo odwołania się do",
      appealDate: "W terminie",
      appealDay: "W terminie",
      appealDay2: "dni od dnia jego otrzymania",
      appealDayTlt: "Liczba dni",
      drivingLicense: "W zakresie prawa jazdy kategorii",
      judgmentNumber: "Orzeczenie psychologiczne nr",
      judgmentNumberAppeal: "Orzeczenie psychologiczne odwoławcze nr",
      legalBais: "Podstawa prawna badania",
      statement: "Stwierdzam*",
      occupationalMedicineStatement: "Orzekam*",
      statementThat: "Stwierdzam, że",
      nextLegalBasis: "Podstawa prawna następnego badania",
      remarks: "Uwagi",
      ART_1: "art. 39k ust. 3 pkt 1",
      ART_2: "art. 39k ust. 3 pkt 2",
      examiner_ART_1: "art. 34 ust. 5 pkt 1",
      examiner_ART_2: "art. 34 ust. 5 pkt 2",
      header: {
        base: "Orzeczenie",
        baseAppeal: "Orzeczenie odwoławcze",
        baseAppealFooter: "Niniejsze orzeczenie jest ostateczne.",
        civilExplosives: "Działalność koncesjonowana - do użytku cywilnego",
        civilExplosivesAppeal: "Działalność koncesjonowana - do użytku cywilnego - odwołanie",
        driver: "Kierowanie pojazdami",
        emergencyVehicleDriver: "Kierowca pojazdu uprzywilejowanego",
        firearmLicense: "Pozwolenie na broń",
        militaryExplosives:
          "Działalność koncesjonowana - o przeznaczeniu wojskowym lub policyjnym",
        physicalProtection: "Pracownik ochrony",
        profesionalDriver: "Stanowisko kierowcy",
        drivingInstructor: "Instruktor",
        drivingExaminer: "Egzaminator",
        occupationalMedicine: "Medycyna pracy",
      },
      emergencyVehicleDriver: {
        true: "brak przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym wartości pieniężne",
        false:
          "istnienie przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym wartości pieniężne",
      },
      drivingVehicles: {
        true: "brak przeciwwskazań psychologicznych kierowania pojazdami",
        false: "istnienie przeciwwskazań psychologicznych kierowania pojazdami",
      },
      physicalProtection: {
        true: "posiada zdolność do wykonywania zadań pracownika ochrony fizycznej",
        false:
          "nie posiada zdolności do wykonywania zadań pracownika ochrony fizycznej",
      },
      civilExplosives: {
        true: "nie wykazuje zaburzeń funkcjonowania psychologicznego, a tym samym może wykonywać działalność w zakresie nabywania oraz przechowywania materiałów wybuchowych przeznaczonych do użytku cywilnego",
        false:
          "wykazuje zaburzenia funkcjonowania psychologicznego, a tym samym nie może wykonywać działalności w zakresie nabywania oraz przechowywania materiałów wybuchowych przeznaczonych do użytku cywilnego",
      },
      drivingInstructor: {
        true: "brak przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
        false:
          "istnienie przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
      },
      drivingExaminer: {
        true: "brak przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
        false: "istnienie przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
      },
      militaryExplosives: {
        true: "nie wykazuje istotnych zaburzeń funkcjonowania psychologicznego, które stanowią przeszkodę do wykonywania lub kierowania działalnością gospodarczą w zakresie wytwarzania i obrotu materiałami wybuchowymi, bronią, amunicją oraz wyrobami i technologią o przeznaczeniu wojskowym lub policyjnym",
        false:
          "wykazuje istotne zaburzenia funkcjonowania psychologicznego, które stanowią przeszkodę do wykonywania lub kierowania działalnością gospodarczą w zakresie wytwarzania i obrotu materiałami wybuchowymi, bronią, amunicją oraz wyrobami i technologią o przeznaczeniu wojskowym lub policyjnym",
      },
      professionalDriver: {
        true: "brak przeciwwskazań psychologicznych do wykonywania pracy na stanowisku kierowcy",
        false:
          "istnienie przeciwwskazań psychologicznych do wykonywania pracy na stanowisku kierowcy",
      },
      firearmLicense: {
        true: "nie należy do osób wymienionych w art. 15 ust. 1 pkt 3 ustawy z dnia 21 maja 1999 r. o broni amunicji i może dysponować bronią",
        false:
          "należy do osób wymienionych w art. 15 ust. 1 pkt 3 ustawy z dnia 21 maja 1999 r. o broni amunicji i nie może dysponować bronią",
      },
      occupationalMedicine: {
        true: "brak przeciwwskazań psychologicznych do",
        false: "istnienie przeciwwskazań psychologicznych do",
        justification: "Uzasadnienie",
      },
    },
    ExaminationCard: {
      header: "Karta badania",
      headerTEST2WORK: "Karta badania TEST2WORK",
      headerTEST2SAFE: "Karta badania TEST2SAFE",
      headerTEST2ARMS: "Karta badania TEST2ARMS",
      headerTEST2DRIVE: "Karta badania TEST2DRIVE",
      fetchUpdated: "Aktualizuj danymi z wywiadu i wynikami",
      tooltipFetchUpdated:
        "Ta akcja usunie wszystkie niezapisane zmiany z Przebiegu Badania",
      tooltipFetchUpdatedDisabled:
        "W celu odblokowania akcji wybierz normę badania.",
      tabs: {
        examinee: "Osoba badana",
        courseOfExamination: "Przebieg badania",
        results: "Wyniki badania psychologicznego",
      },
      examinee: {
        chosenNormSet: "Norma zastosowana w badaniu",
        examinationDate: "Data Badania",
        previousExaminationDate: "Data poprzedniego badania",
        aim: "Cel badania",
        entityDirectingToExamination: "Podmiot kierujący na badanie",
        t2dAim: {
          drivingVehiclesAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do kierowania pojazdami.",
          professionalDriverAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do wykonywania pracy na stanowisku kierowcy.",
          emergencyDriverAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym środki pieniężne.",
          drivingInstructorAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy.",
        },
      },
      examinationCourse: {
        forcedUpdateHeader:
          "Zaktualizuj Kartę Badania danymi z Wywiadu i Wynikami",
        forcedUpdateBody:
          "W celu odblokowania formularza wypełnij Wywiad dla Badania, wybierz normę w zakładce Osoba Badana i zaktualizuj Kartę Badania danymi z Wywiadu i Wynikami.",
        header: "Dane z wywiadu bezpośredniego i obserwacji osoby badanej",
        methodology: "Metody badania i wyniki",
        recommendations: "Zalecenia",
        conclusions: "Wnioski",
        conclusionsRecomendation: "Wnioski i zalecenia",
        careerHistory: "Przebieg pracy",
        examinationResultsHeader:
          "Nazwa testu i wyniki (w ramach rekomendowanej metodyki)",
        examinationExtendedResultsHeader: "Nazwa testu i wyniki dodatkowe",
        cognitiveResultsHeader:
          "Ocena i opis osoby badanej pod względem sprawności intelektualnej i procesów poznawczych (w ramach rekomendowanej metodyki)",
        cognitiveExtendedResultsHeader:
          "Ocena i opis osoby badanej pod względem sprawności intelektualnej i procesów poznawczych wynki dodatkowe",
        personalityResultsHeader:
          "Ocena i opis osoby badanej pod względem osobowości, z uwzględnieniem funkcjonowania w trudnych sytuacjach, a także dojrzałości społecznej (w ramach rekomendowanej metodyki)",
        personalityExtendedResultsHeader:
          "Ocena i opis osoby badanej pod względem osobowości, z uwzględnieniem funkcjonowania w trudnych sytuacjach, a także dojrzałości społecznej wyniki dodatkowe",
        psychomotorResultsHeader:
          "Sprawność psychomotoryczna (w ramach rekomendowanej metodyki)",
        psychomotorExtendedResultsHeader:
          "Sprawność psychomotoryczna wyniki dodatkowe",
        occupationalMedicineAdditionalInfo:
          "Dodatkowe informacje - Medycyna pracy",
        examinationResultsT2WHeader: "Rekomendowana metodyka",
        examinationExtendedResultsT2WHeader: "Dodatkowe",
        addExaminationExtendedResults: "Dodaj wynik dodatkowy",
        deletePopup: {
          headerText: "Czy na pewno chcesz usunąć wynik?",
          contentText:
            "Po usunięciu wyniku można przywtucić jego orginalną treść poprzez aktualizację danych",
          confirmationText: "Tak, chcę usunąć wynik",
        },
      },
      judgment: {
        result: "Wynik badania psychologicznego",
        sent: "Orzeczenie/kopię orzeczenia wysłano, jeżeli dotyczy",
        judgment: "Treść orzeczenia",
        justification: "Uzasadnienie orzeczenia",
        justificationText: "Treść uzasadnienia",
        recommendations: "Zalecenia",
        to: "do",
        t2d: {
          lack: "brak",
          existing: "istnieje",
          questions: {
            drivingVehiclesContraindication:
              "Przeciwwskazania do kierowania pojazdami.",
            professionalDriverContraindication:
              "Przeciwwskazania do wykonywania pracy na stanowisku kierowcy.",
            emergencyVehicleDriverContraindication:
              "Przeciwwskazania do kierowania pojazdem uprzywilejowanym lub przewożącym środki pieniężne.",
            drivingInstructorContraindication:
              "Przeciwwskazania do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy.",
          },
        },
      },
    },
  },
};
