/* eslint-disable camelcase */
export const en = {
  HelpDocs: {
    contractTitle: "Contracts",
    contractName1: "Psychological Contract 2024",
    contractName2: "Psychological Contract RU 2024",
    contractName3: "Psychological Contract UA 2024",
    contractName4: "Personal Data Protection Policy - Information Clause 2024",
    handbookTitle: "Handbook and Test Catalog",
    handbookName1: "Characteristics of Diagnostic Areas",
    handbookName2: "Psychomotor Tests",
    handbookName3: "Cognitive Tests",
    handbookName4: "Personality Tests",
    handbookName5: "Test Catalog 03-2024",
    modulesTitle: "Interview Modules",
    modulesName1: "Health Situation",
    modulesName2: "Social and Family Situation",
    modulesName3: "Observation",
    modulesName4: "Detectives Protection Weapons MTD I",
    modulesName5: "License MTD II",
    modulesName6: "Occupational Medicine MTD III",
    modulesName7: "Service Drivers MTD III",
    modulesName8: "Working at Heights MTD III",
    modulesName9: "Forklifts MTD III",
    modulesName10: "Transport Psychology Vehicle Operation MTD IV"
  },
  Format: {
    ViewDateTime: "MM/DD/YYYY HH:mm",
    FormDateFormat: "MM/DD/YYYY",
    ExamineeViewDateFormat: "MM/DD/YYYY",
  },
  Buttons: {
    accept: "Agree",
    addExaminee: "Add participant",
    addDirectory: "Add folder",
    assign: "Assign",
    alreadyAssigned: "Assigned",
    assignExamination: "Assign assessment",
    alreadyAssignedExamination: "Assessment in progress",
    back: "Back",
    backToLogin: "Back to login page",
    begin: "Start",
    cancel: "Cancel",
    change: "Change",
    clear: "Clear",
    close: "Close",
    confirm: "Confirm",
    continue: "Next",
    createExamination: "Create assessment",
    delete: "Delete",
    deleteExaminee: "Delete participant",
    downloadAll: "Download all",
    edit: "Edit participant",
    editExaminee: "Edit participant",
    examinationWithNoLawBase: "Assessment without a legal basis",
    examinationWithLawBase: "Assessment with a legal basis",
    exit: "Exit",
    generate: "Generate",
    generateAndPrint: "Generate and print",
    go: "Go",
    goToDashboard: "Go to user panel",
    login: "Login",
    move: "Move",
    moveExaminee: "Move participant",
    moveExaminees: "Move participants",
    ok: "Ok",
    print: "Print",
    printContract: "Print contract",
    save: "Save",
    savePassword: "Save password",
    savePin: "Save PIN",
    saveAndPrint: "Save and print",
    saveSettings: "Save settings",
    setPin: "Set PIN",
    search: "Search",
    setPassword: "Set password",
    setNewPassword: "Set new password",
    sendLink: "Send link",
    skipTests: "Skip tests",
    understood: "Understood",
    addPsychLab: "Add customer",
    addPsychLabLic: "Add license",
    addPsychUser: "Add user",
    addUser: "Add user",
    deletePsychLab: "Delete customer",
    deletePsychLabLic: "Delete license",
    editPsychLab: "Edit customer",
    editPsychLabLic: "Edit license",
    editPsychLabUser: "Edit user",
    exportActivities: "Export to XLS",
    loginPsychLab: "Log in to client account",
    showActivities: "Show",
  },
  Common: {
    menu: {
      contact: "Help",
      dashboard: "User panel",
      folders: "Examinee records",
      search: "Search",
      logout: "Log out",
      settings: "Settings",
      psychlabs: "Client records",
      breadcrumbsPsychLab: "Client records"
    },
    seo: {
      addExaminee: "Add participant",
      dashboard: "User panel",
      editExaminee: "Edit participant",
      helpdesk: "Help",
      helpdeskDocuments: "Help documents",
      judgement: "Judgment",
      login: "Login",
      onboarding: "Onboarding",
      report: "Report",
      resetPassword: "Reset password",
      search: "Search",
      settings: "Settings",
      examinationCard: "Examination card",
      addPsychLab: "Add client account",
      addPsychLabLic: "Add license",
      folders: "Folders",
      examinationAssignment: "Examination assignment with legal basis",
      setNewPassword: "Set new password"
    },
    warningPopup: {
      text: "The changes you make will not be saved",
      header: "Are you sure you want to leave?",
      textSave: "Required fields may also be located on other tabs",
      headerSave: "Please complete all required fields!",
      promptLogout: "You will be automatically logged out in PROMPT_TIME_MINUTES minute due to inactivity."
    },
    logAdminAsUserPopup: {
      text: "Failed to log in to client account",
      header: "Error"
    },
    deleteExamineePopup: {
      text: "Once the participant is deleted, it will not be possible to restore the data",
      header: "Are you sure you want to delete the participant?",
    },
  },
  Contact: {
    header: "Technical Support",
  },
  Cookies: {
    header: "Permission to use cookies",
    consent: `This website uses cookies, which are used to ensure the best possible performance of this application. If you agree to saving the information contained in cookies, click the I AGREE button. If you do not consent, you will not be able to use the application and perform the assigned procedures.`,
  },
  Dashboard: {
    header: "Recently added participants",
    footer: {
      lastLogin: "Last login",
      lastFailedLogin: "Last failed login",
      passwordExpired: "Your password will expire in",
      day: "day",
      days: "days",
    },
    emptyState: {
      header: "No recently added participants",
      info: "To see the list of assessment, assign at least one assessment.",
    },
    kebabMenu: {
      print: "Print contract",
      profile: "Go to the participant's profile",
    },
  },
  AdminDashboard: {
    header: "Client Records",
    emptyState: {
      header: "No client accounts added yet",
      info: "To see the list of clients, add at least one account."
    },
    emptyStateUsers: {
      header: "No user accounts added yet",
      info: "To see the list of users, add at least one user account."
    },
    emptyStateLicense: {
      header: "No licenses added yet",
      info: "To see the license, please add it."
    },
    kebabMenu: {
      print: "Print contract",
      profile: "Go to examinee profile"
    },
    PsychologicalLabsList: {
      labels: {
        name: "Client name",
        clientId: "Client ID",
        status: "Status",
        psychCnt: "Number of users",
        createDate: "Creation date",
        expireDate: "Account expiry date",
        details: "Details",
        usersCnt: "Number of users",
        examineesCnt: "Number of examinees",
        examinationsCnt: "Number of examinations",
        taskPerPage: "Rows per page:",
        all: "all",
        of: "of",
        moreThen: "more than"
      },
      labStatus: {
        enabled: "active",
        disabled: "inactive"
      }
    },
    Licence: {
      labels: {
        methodologyList: "Available methodologies",
        testsList: "Available tests",
        licenseDate: "License issue date",
        expireDate: "License expiry date"
      },
      deletePopup: {
        headerText: "Are you sure you want to delete the license?",
        contentText: "After deleting the license, it will not be possible to assign examinations",
        confirmationText: "Yes, I want to delete the license"
      }
    },
    PsychLab: {
      deletePopup: {
        headerText: "Are you sure you want to delete the client account?",
        contentText: "After deleting the client account, it will not be possible to restore the data",
        confirmationText: "Yes, I want to delete the client account"
      }
    },
    StatsList: {
      statFileName: "examination_statistics.xlsx"
    },
    ActivitiesList: {
      labels: {
        date: "Date",
        action: "Action",
        executor: "Executor",
        context: "Description",
        status: "Status",
        errorMsg: "Error description"
      },
      labStatus: {
        success: "ok",
        error: "error"
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "Are you sure you want to delete the user account?",
        contentText: "After deleting the user account, it will not be possible to restore it",
        confirmationText: "Yes, I want to delete the user account"
      },
      labels: {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        examineeCnt: "Number of participants",
        examinationCnt: "Number of examinations",
        isActive: "Status",
        isStaff: "Role"
      },
      labStatus: {
        enabled: "active",
        disabled: "inactive",
        adminRole: "administrator",
        superUserRole: "super admin",
        userRole: "user",
        userBaseRole: "registrar"
      },
      toolTip: {
        edit: "edit",
        delete: "delete"
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Add client account",
        edit: "Edit client account",
        addLicense: "Add license",
        editLicense: "Edit license",
        addUser: "Add user account",
        editUser: "Edit user account",
        dashboard: "User panel",
        psychLabs: "Client records"
      },
      header: {
        add: "Add client account",
        edit: "Edit client account",
        addUser: "Add user account",
        editUser: "Edit user account",
        addLicense: "Add license",
        editLicense: "Edit license"
      },
      PsychLabView: {
        labels: {
          details: "Details",
          download: "Download",
          edit: "Edit",
          generate: "Generate",
          name: "Test name",
          nameFile: "File name",
          print: "Print",
          result: "Result",
          type: "Document type"
        },
        tabs: {
          psychLabData: "Client data",
          license: "License",
          users: "Users",
          activityLogs: "Activity logs",
          activityLogsFile: "activity_logs",
          activityId: "id",
          activityDate: "date",
          activityAction: "action name",
          activityStatus: "status",
          activityContext: "description",
          activityExecutor: "executor",
          stats: "Statistics",
          theme: "Appearance"
        }
      }
    },
  },
  ExaminationAssignmentView: {
    header: "Assign assessment",
    labels: {
      chooseExaminationType: "Select an assessment type",
      chooseLawBase: "Select a legal basis",
      description: "Description",
      legalBase: "Legal basis",
      reason: "Purpose of the assessment",
      suggestedTestMethods: "PROPOSED METHODICAS",
      methodologyTest2Safe: "TEST2SAFE - psychological testing methodology for security staff and detectives",
      methodologyTest2Drive: "TEST2DRIVE - methodology for conducting driver examinations in transport psychology",
      methodologyTest2Arms: "TEST2ARMS - psychological testing methodology, licensed activity",
      methodologyTest2Work: "TEST2WORK - psychological testing methodology in occupational medicine"
    },
    popup: {
      header:
        "Some tests recommended for the selected methodic have been disabled.",
      content: "Are you sure you want to disable these tests?",
      checkbox: "Remember your selection",
    },
    license: "The test is not available in the assigned license",
  },
  PsychologicalTests: {
    psychomotor: "Psychomotor tests",
    cognitive: "Cognitive tests",
    personality: "Personality tests",
  },
  ExamineeList: {
    labels: {
      examineLastDate: "Last assessment date",
      contract: "Contract",
      examine: "Assessment",
      firstName: "First name",
      folder: "Folder",
      allFinishExam: "Assigned / done",
      lastName: "Lastname",
      peselSlashIdentifier: "PESEL / ID",
      profile: "View profile",
      added: "Added",
    },
  },
  ExaminationList: {
    withoutLegalBasis: "Assessment without a legal basis",
    labels: {
      assign: "Assigned",
      assignDate: "Assigned date",
      create: "Created",
      createDate: "Created date",
      contract: "Contract",
      documentation: "Documentation",
      end: "Ended",
      endDate: "Ended date",
      interview: "Interview",
      identificator: "Assessment ID",
      language: "Language",
      nameArchiveExamination: "Battery name",
      nameExamination: "Assessment name",
      start: "Started",
      startDate: "Started date",
    },
    kebabMenu: {
      delete: "Delete assessment",
    },
    deletePopup: {
      headerText: "Are you sure you want to delete the assessment?",
      contentText: "Once an assessment is deleted, it will not be possible to restore it",
      confirmationText: "Yes, I want to delete the assessment",
    },
    toolTip: {
      contract: "Generate contract",
      interview: "Interview",
      documentation: "Documentation",
      delete: "Delete",
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "There are no archival assessments assigned to this participant",
    },
  },
  ExaminationView: {
    header: "Dokumentation",
    breadcrumbs: {
      archive: "Archival assessments",
    },
    details: {
      createDate: "Created date",
      endDate: "Ended date",
      identificator: "Assessment ID",
      language: "Language",
      name: "Assessment name",
      startDate: "Started date",
      type: "Battery name",
    },
    protocols: {
      language: "Protocols language",
      testProtocol: "Test protocol",
    },
    labels: {
      details: "Details",
      download: "Download",
      edit: "Edit",
      generate: "Generate",
      name: "Test name",
      nameFile: "File name",
      print: "Print",
      result: "Result",
      type: "Document type",
    },
    tabs: {
      documentation: "Documentation",
      protocols: "Protocols",
      reports: "Reports",
    },
    common: {
      unavailableInLanguageOption: "Not available in the selected language version.",
    },
    aimsKind: {
      aimKind_1: "Psychological opinion - detectives",
      aimKind_3: "Certificate - physical security worker",
      aimKind_8: "Appeal certificate - physical security worker",
      aimKind_9: "Certificate - firearms permit",
      aimKind_10: "Certificate - licensed activity for military or police purposes",
      aimKind_11: "Certificate - licensed activity for civilian purposes",
      aimKind_12: "Appeal certificate - licensed activity for civilian purposes",
      aimKind_13: "Certificate - occupational medicine",
      aimKind_14: "Certificate - occupational medicine mining and mining rescue",
      aimKind_15: "Certificate - occupational medicine train driver license",
      aimKind_16: "Certificate - driver category C1, C1+E, C, C+E, D1, D1+E, D and D+E or tram driving",
      aimKind_17: "Certificate - driver license reinstatement category AM, A1, A2, A, B1, B, B+E and T",
      aimKind_18: "Certificate - driver license reinstatement psychological contraindications",
      aimKind_19: "Certificate - license extension category C1, C1+E, C, C+E, D1, D1+E, D and D+E or tram driving",
      aimKind_20: "Certificate - driver post-accident examination",
      aimKind_21: "Certificate - driver examination after alcohol or similar substances consumption",
      aimKind_22: "Certificate - driver examination after exceeding 24 points",
      aimKind_23: "Certificate - driver examination after at least 2 offenses",
      aimKind_24: "Certificate - driver examination psychological contraindications",
      aimKind_25: "Certificate - emergency vehicle driver",
      aimKind_26: "Certificate - driver post-accident examination (referred by starost)",
      aimKind_27: "Certificate - performing instructor duties",
      aimKind_28: "Certificate - performing examiner duties",
      aimKind_29: "Certificate - driver student or candidate for secondary school category C or C+E",
      aimKind_30: "Certificate - driver student or candidate for vocational course category C or C+E",
      aimKind_31: "Certificate - professional driver referred by employer",
      aimKind_32: "Certificate - self-employed professional driver"
    },
  },
  ExamineeView: {
    anonymity: "Anonymous",
    userData: {
      defaultAnonFirstName: "Examinee",
      defaultAnonLastName: "Anonymous",
      pesel: "PESEL",
      sex: "Gender",
      birthDate: "Date of birth",
      address: "Address",
      education: "Education",
      drivingLicense: "Driving license category",
      identityCard: "Identity document",
      serialNumber: "Serie and number",
      identificator: "ID",
      drivingLicenseIssuer: "The entity issuing the driving license",
      examineName: "FirstName and Lastname",
    },
    drivingLicense: {
      am: "AM",
      a1: "A1",
      a2: "A2",
      a: "A",
      b1: "B1",
      b: "B",
      be: "B+E",
      c: "C",
      c1: "C1",
      c1e: "C1+E",
      ce: "C+E",
      d: "D",
      d1: "D1",
      d1e: "D1+E",
      de: "D+E",
      t: "T",
      tramwaj: "TRAMWAY",
    },
    tabs: {
      archive: "Archival assessments",
      data: "Participant data",
      examination: "Assessments",
    },
    kebabMenu: {
      edit: "Edit participant data",
      delete: "Delete participant",
    },
    emptyState: {
      header: "There are no assessments assigned to this participant",
      info: "To see the list of assessment, assign at least one assessment.",
    },
    translations: {
      passport: "passport",
      id_card: "ID card",
      male: "male",
      female: "female",
      primary: "primary education",
      lowerSecondary: "junior high education",
      basicVocational: "basic vocational education",
      basicIndustry: "basic industry education",
      secondaryIndustry: "secondary vocational education",
      secondary: "secondary education",
      higher: "higher education",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Add participant",
      edit: "Edit participant",
      dashboard: "User panel",
      directories: "Managing Participant folders",
    },
    header: {
      add: "Add participant",
      edit: "Edit participant",
    },
  },
  Forms: {
    fields: {
      address: "Address",
      additionalInfo: "Additional information",
      appealAuthority: "Name of the institution",
      appealDeadline: "Date",
      birthDate: "Date of birth",
      city: "City",
      country: "Country",
      diffrentIdentificationNumber: "Different ID",
      directory: "Folder",
      documentDate: "Date of issue",
      drivingLicense: "Driving license category",
      drivingLicenseIssuer: "Entities issuing driving licenses",
      education: "Education",
      identificationNumber: "PESEL",
      identityDocumentType: "Identity document",
      identityDocumentNumber: "Serie and number",
      interval: "Select a confidence interval",
      judgmentNumber: "Judgment number",
      languageReport: "Select report language",
      legalBasis: "Legal basis",
      months: "months",
      name: "First name",
      nextExamination: "Date of next examination",
      postalCode: "Zip code",
      recipient: "Select a recipient",
      sex: "Gender",
      standard: "Select norm",
      surname: "Last name",
      years: "Years",
      year: "Year",
      examineeRegistryId: "Number in the register",
      age: "Age",
      date: "Date",
      institutionName: "Name of the institution",
    },
    options: {
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Yes",
        false: "No",
      },
      education: {
        primary: "Primary education",
        lowerSecondary: "Junior high education",
        secondary: "Secondary education",
        basicVocational: "Basic vocational education",
        basicIndustry: "Basic industry education",
        secondaryIndustry: "Secondary vocational education",
        higher: "Higher education",
      },
      identityDocument: {
        idCard: "ID card",
        passport: "Passport",
      },
      sex: {
        female: "Female",
        male: "Male",
      },
      country: {
        poland: "Poland",
        austria: "Austria",
        bulgaria: "Bulgaria",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czechRepublic: "Czech Republic",
        denmark: "Denmark",
        estonia: "Estonia",
        finland: "Finland",
        france: "France",
        greece: "Greece",
        spain: "Spain",
        netherlands: "Netherlands",
        ireland: "Ireland",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        latvia: "Latvia",
        malta: "Malta",
        germany: "Germany",
        portugal: "Portugal",
        romania: "Romania",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        sweden: "Sweden",
        hungary: "Hungary",
        italy: "Italy",
        afghanistan: "Afghanistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Saudiarabia",
        argentina: "Argentina",
        armenia: "Armenia",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        bolivia: "Bolivia",
        bosniaAndHerzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        chile: "Chile",
        china: "China",
        chad: "Chad",
        montenegro: "Montenegro",
        democraticRepublicOfTheCongo: "Democratic Republic of the Congo",
        dominicanRepublic: "Dominican Republic",
        ecuador: "Ecuador",
        ethiopia: "Ethiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Georgia",
        guatemala: "Guatemala",
        honduras: "Honduras",
        india: "India",
        indonesia: "Indonesia",
        iran: "Iran",
        iceland: "Iceland",
        israel: "Israel",
        jamaica: "Jamaica",
        japan: "Japan",
        yemen: "Yemen",
        jordan: "Jordan",
        cambodia: "Cambodia",
        cameroon: "Cameroon",
        qatar: "Qatar",
        kazakhstan: "Kazakhstan",
        congo: "Congo",
        southKorea: "Southkorea",
        laos: "Laos",
        northernMacedonia: "Northern Macedonia",
        madagascar: "Madagascar",
        malaysia: "Malaysia",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Mexico",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambique",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nicaragua",
        norway: "Norway",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        centralAfricanRepublic: "Central African Republic",
        russia: "Russia",
        rwanda: "Rwanda",
        elSalvador: "Elsalvador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somalia",
        unitedStatesOfAmerica: "United States of America",
        sudan: "Sudan",
        southSudan: "Southsudan",
        switzerland: "Switzerland",
        tajikistan: "Tajikistan",
        thailand: "Thailand",
        turkey: "Turkey",
        uganda: "Uganda",
        ukraine: "Ukraine",
        uruguay: "Uruguay",
        venezuela: "Venezuela",
        unitedKingdom: "United Kingdom",
        vietnam: "Vietnam",
        zambia: "Zambia",
        unitedArabEmirates: "United Arab Emirates",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Participant",
        psychologist: "Psychologist",
        specialist: "Specialist",
      },
      languagesDocumentation: {
        pl: "polish",
        en: "english",
      },
    },
    extraFields: "Supplementary data",
    requiredFields: "Mandatory data",
    anonymous: "Anonymous test",
    snackbar: {
      peselLength: "PESEL must consist of 11 digits",
      peselNumber: "PESEL may only consist of numbers",
      required: "All required fields must be filled out",
    },
  },
  FormValidation: {
    max: "The name cannot contain more than 128 characters",
    required: "This field is required",
    Pin: {
      length: "Field must have only 4 digit characters",
    },
    Password: {
      length: "Minimum 8 characters",
      characters:
        "Must contain a capital letter, a special character and a number",
    },
  },
  Helpdesk: {
    header: "Help",
    subheader: "Help desk",
    help: "Remote help",
    contact: "Contact",
    phoneNo: "phone",
    mobile: "mobile",
    documents: {
      header: "Help documents",
      labels: {
        download: "Download",
        name: "Document name",
      },
    },
  },
  Interview: {
    header: "Wywiad",
    inputAdditionalInformation: "Wpisz dodatkowe informacje",
    inputHoursOfWork: "Wpisz ilość godzin pracy w ostatniej dobie",
    years: "Lata",
    months: "Miesiące",
    hours: "Godziny",
    tabs: {
      currentPsychophysicalState: "Sytuacja zdrowotna",
      socialAndFamilySituation: "Sytuacja społeczna i rodzinna",
      observation: "Obserwacja",
      drivingVehicles: "Kierowanie pojazdami",
      occupationalMedicine: "Medycyna pracy",
      securityAndDetectives: "Detektywi i pracownicy ochrony",
      licensedActivities: "Działalność koncesjonowana",
    },
    questions: {
      questionAnswers: {
        yes: "tak",
        no: "nie",
      },
      questionTexts: {
        when: "Kiedy ?",
        sinceWhen: "Od kiedy ?",
        forHowLong: "Do kiedy ?",
        why: "Z jakiego powodu ?",
        whatAreTheConsequences: "Jakie były skutki zdrowotne ?",
        describeTheCircumStancesOfUsage: "Opisz okoliczności tego użycia",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Aktualny stan psychofizyczny",
            criticalEvents: "Zdarzenia krytyczne",
          },
          questionExtendedTexts: {
            commonText: "Opisz okoliczności tego zdarzenia/sytuacji",
          },
          questionTexts: {
            question1:
              "Podstawowe cele wykorzystania broni:",
            question2:
              "Czy kiedykolwiek miało miejsce użycie broni {...} lub innych środków przymusu bezpośredniego?",
            question3: "Jak często miało miejsce użycie broni w ciągu ostatnich 5 lat?",
            question4: "Stanowisko / wykonywane czynności",
            question5: "Czynniki szkodliwe/uciążliwe występujące na stałe w miejscu pracy",
            question6: "Staż pracy ogółem",
            question7:
              "Staż pracy na obecnym stanowisku" +
              " czy pojawiło się kiedykolwiek rozpoznanie następujących chorób:",
            question7a:
              "jeżdżę najczęściej nocą",
            question7b:
              "jeżdżę zwykle w ciągu dnia",
            question7c:
              "jeżdżę zarówno w ciągu dnia jak i nocą",
            question7d: "d. cukrzycy",
            question7e: "e. epilepsji",
            question7f:
              "f. chorób psychicznych (depresja, nerwica, {...} leczenie psychiatryczne (dopytać o leczenie psychiatryczne członka bliskiej rodziny)" +
              " leczenie psychiatryczne (dopytać o leczenie psychiatryczne członka bliskiej rodziny)",
            question8:
              "Stwierdzono chorobę zawodową" +
              " ma świadomość działań ubocznych i dopytać o inne leki wpływające na funkcje psychiczne)",
            question9: "Przyznano świadczenie rentowe",
            question10: "Osoba badana uległa wypadkowi w pracy",
            question10a: "Kiedy? Jakie były skutki zdrowotne?",
            question10b: "b. lekarza psychiatry",
            question10c: "c. neurologa",
            question11: "Orzeczono niepełnosprawność",
            question12: "Staż w prowadzeniu pojazdów ogólnie",
            question13: "Liczba rocznie przejechanych kilometrów",
            question14:
              "Liczba godzin spędzanych na prowadzeniu samochodu",
            question15:
              "Orzeczenie niezdolności do prowadzenia pojazdów" +
              " roztargnienie lub problemy z pamięcią lub orientacją",
          },
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1:
              "Poważny konflikt lub problem związany z pracą " +
              "(np. wypadek w pracy, nagana, sąd pracy, zwolnienie dyscyplinarne, mobbing)",
            question2:
              "Problemy na etapie kształcenia " +
              "(np. powtarzanie klasy, wyrzucenie ze szkoły, zawieszenie w prawach ucznia)",
            question3:
              "Poważny konflikt rodzinny lub problemy w sytuacjach prywatnych " +
              "(np. sprawy sądowe, udział w bójkach, można dopytać o status związku, dzieci) ",
            question4:
              "Sytuacja bezpośredniego zagrożenia życia (np. pożar, powódź i inne katastrofy)",
            question5:
              "Doznanie aktu przemocy fizycznej na własnej osobie (np. napaść, kradzież, pobicie)",
            question6:
              "Zaangażowanie w działania organizacji społecznych (np. wolontariat)",
            question7: "Karalność (np. otrzymanie mandatu, wyrok sądu) ",
          },
        },
        observation: {
          questionTexts: {
            question1: "Kontakt z rozmówcą",
            question2: "Język i retoryka wypowiedzi",
            question3: "Nastrój i nastawienie",
            question4: "Opanowanie i kontrola zachowań i emocji",
          },
          questionAnswers: {
            question1a:
              "do celów zawodowych (detektyw, pracownik ochrony)" +
              " zachowuje się pewnie i swobodnie, nie pozwala się wytrącić z równowagi czy zniecierpliwić," +
              " zachowuje się spontanicznie i naturalnie. ",
            question1b:
              "do celów łowieckich" +
              " choć widoczne są oznaki pewnego zdenerwowania. ",
            question1c:
              "do celów sportowych" +
              " małe bodźce powodują wytrącenie z równowagi, widoczne są wyraźne oznaki zdenerwowania i zniecierpliwienia. ",
            question2a:
              "Formułuje wypowiedzi używając poprawnego języka, {...}  buduje jasne, czytelne zdania, utrzymuje logiczną strukturę wypowiedzi, {...} wykorzystuje bogaty zasób słownictwa." +
              " buduje jasne, czytelne zdania, utrzymuje logiczną strukturę wypowiedzi," +
              " wykorzystuje bogaty zasób słownictwa.  ",
            question2b:
              "Formułuje poprawnie wypowiedzi,{...} generalnie utrzymuje ich logiczną strukturę; zasób słów przeciętny, ale poprawny." +
              "generalnie utrzymuje ich logiczną strukturę; zasób słów przeciętny, ale poprawny. ",
            question2c:
              "Formułuje krótkie, zdawkowe wypowiedzi, często niespójne lub niejasne; {...} używa niepoprawnych zwrotów i sformułowań; zdania są bardzo proste, {...} słownictwo bardzo ubogie, występują błędy gramatyczne." +
              " używa niepoprawnych zwrotów i sformułowań; zdania są bardzo proste," +
              " słownictwo bardzo ubogie, występują błędy gramatyczne. ",
            question3a:
              "mniej niż 3 000 km rocznie",
            question3b:
              "między 3 000 – 15 000 km rocznie",
            question3c:
              "między 15 000 - 70 000 km rocznie" +
              " wyraźna skłonność do pesymistycznego odbioru świata; okazywanie niezadowolenia. ",
            question4a:
              "Udział w wypadku w miejscu pracy" +
              " adekwatnie dostosowuje się do pojawiających się okoliczności. ",
            question4b:
              "Jakie były przyczyny, konsekwencje wypadku - czy nastąpiło uszkodzenie mienia, zdrowia?" +
              " najczęściej adekwatnie reaguje na pojawiające się okoliczności. ",
            question4c:
              "między 15 - 40 godzin tygodniowo" +
              " pojawiają się nieadekwatne zachowania lub emocje. ",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Charakterystyka przejeżdżanych tras",
          },
          questionTexts: {
            question1: "Staż w prowadzeniu pojazdów ogólnie",
            question2: "Staż w prowadzeniu pojazdów na stanowisku kierowcy",
            question3: "Liczba rocznie przejechanych kilometrów",
            question4: "Liczba godzin spędzanych na prowadzeniu samochodu",
            question5: "Długość tras ",
            question6: "Różnorodność",
            question7: "Pory dnia ",
            question8: "Zmiennik",
            question9: "Obszar",
            question10: "Zatrzymanie lub odebranie prawa jazdy",
            question11: "Orzeczenie niezdolności do prowadzenia pojazdów",
            question12: "Kara za wykroczenie lub przestępstwo drogowe",
            question13: "Udział jako kierowca w wypadku samochodowym",
            question14: "Uczestnictwo jako kierowca w kolizji drogowej",
            question15: "Posiadane dodatkowe uprawnienia i licencje",
          },
          questionAnswers: {
            question3a: "mniej niż 3 000 km rocznie",
            question3b: "między 3 000 – 15 000 km rocznie",
            question3c: "między 15 000 - 70 000 km rocznie",
            question3d: "powyżej 70 000 km rocznie",
            question3e: "trudno powiedzieć",

            question4a: "mniej niż 5 godzin tygodniowo",
            question4b: "między 5 - 15 godzin tygodniowo",
            question4c: "między 15 - 40 godzin tygodniowo",
            question4d: "powyżej 40 godzin tygodniowo",
            question4e: "trudno powiedzieć",

            question5a: "Praca monotonna",
            question5b: "Stały duży dopływ informacji",
            question5c: "Wysoki poziom hałasu",

            question6a: "jeżdżę zazwyczaj na stałych trasach",
            question6b: "jeżdżę najczęściej na różnych trasach",

            question7a: "jeżdżę najczęściej nocą",
            question7b: "jeżdżę zwykle w ciągu dnia",
            question7c: "jeżdżę zarówno w ciągu dnia jak i nocą",

            question8a: "Kiedy? Z jakiego powodu?",
            question8b: "jeżdżę najczęściej ze zmiennikiem",

            question9a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question9b: "jeżdżę głownie poza miastem",

            question13a: "mniej niż 3 000 km rocznie",
            question13b: "między 3 000 – 15 000 km rocznie",
            question13c: "między 15 000 - 70 000 km rocznie",

            question14a: "mniej niż 5 godzin tygodniowo",
            question14b: "między 5 - 15 godzin tygodniowo",
            question14c: "między 15 - 40 godzin tygodniowo",

            question15a: "przewóz materiałów niebezpiecznych",
            question15b: "przewóz osób",
            question15c: "instruktor prawa jazdy",
            question15d: "egzaminator prawa jazdy",
            question15e: "brak",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Informacje dotyczące pracy zawodowej",
            workCourseInformation: "Przebieg pracy zawodowej",
            companyCarDrivers: "Kierowcy samochodów służbowych",
            workingAtHeights: "Praca na wysokości",
            forkliftOperators: "Operatorzy wózków widłowych",
            minersAndMinerLifeguards: "Górnicy i ratownicy górniczy",
            trainDriver: "Maszyniści",
          },
          questionTexts: {
            question1: "Zawód wyuczony",
            question2: "Zawód wykonywany",
            question3: "Miejsce pracy / szkoła",
            question4: "Stanowisko / wykonywane czynności",
            question5:
              "Czynniki szkodliwe/uciążliwe występujące na stałe w miejscu pracy",
            question5f: "Czynniki chemiczne",
            question5g: "Inne",
            question6: "Staż pracy ogółem",
            question7: "Staż pracy na obecnym stanowisku",
            question8: "Stwierdzono chorobę zawodową",
            question8a: "Kiedy? Z jakiego powodu?",
            question9: "Przyznano świadczenie rentowe",
            question9a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question10: "Osoba badana uległa wypadkowi w pracy",
            question10a: "Kiedy? Jakie były skutki zdrowotne?",
            question11: "Orzeczono niepełnosprawność",
            question11a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question12: "Staż w prowadzeniu pojazdów ogólnie",
            question13: "Liczba rocznie przejechanych kilometrów",
            question14: "Liczba godzin spędzanych na prowadzeniu samochodu",
            question15: "Orzeczenie niezdolności do prowadzenia pojazdów",
            question16: "Zatrzymanie lub odebranie prawa jazdy",
            question17: "Kara za wykroczenie lub przestępstwo drogowe",
            question18: "Udział jako kierowca w wypadku samochodowym",
            question19: "Uczestnictwo jako kierowca w kolizji drogowej",
            question20:
              "Posiadanie uprawnień do wykonywania pracy na wysokości",
            question21:
              "Wykonywanie prac na wysokości (jeśli tak, dopytać, ile metrów nad ziemią)",
            question22:
              "Subiektywne odczuwanie lęku podczas przebywania na wysokości",
            question23: "Wypadek podczas pracy na wysokości",
            question23a: "Czy nastąpiło uszkodzenie mienia lub zdrowia?",
            question24:
              "Posiadanie uprawnień do wykonywania pracy operatora wózka widłowego",
            question25:
              "Wykonywanie prac związanych z obsługą i prowadzeniem wózka widłowego",
            question26:
              "Wypadek podczas obsługi lub prowadzenia wózka widłowego",
            question26a: "Czy nastąpiło uszkodzenie mienia lub zdrowia?",
          },
          questionAnswers: {
            question5a: "Praca monotonna",
            question5b: "Stały duży dopływ informacji",
            question5c: "Wysoki poziom hałasu",
            question5d:
              "Praca z dużym obciążeniem psychicznym (odpowiedzialność i decyzyjność)",
            question5e: "Promieniowanie jonizujące",
            question5f: "Czynniki chemiczne",
            question5g: "Inne",

            question13a: "mniej niż 3 000 km rocznie",
            question13b: "między 3 000 – 15 000 km rocznie",
            question13c: "między 15 000 - 70 000 km rocznie",
            question13d: "powyżej 70 000 km rocznie",
            question13e: "trudno powiedzieć",

            question14a: "mniej niż 5 godzin tygodniowo",
            question14b: "między 5 - 15 godzin tygodniowo",
            question14c: "między 15 - 40 godzin tygodniowo",
            question14d: "powyżej 40 godzin tygodniowo",
            question14e: "trudno powiedzieć",

            question18a: "jako sprawca/współsprawca",
            question18b: "jako poszkodowany",
            question18c: "nie dotyczy",

            question19a: "jako sprawca/współsprawca",
            question19b: "jako poszkodowany",
            question19c: "nie dotyczy",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1:
              "Staż pracy ogółem w zakładzie pracy zajmującym " +
              "się działalnością koncesjonowaną",
            question2: "Stanowisko/ wykonywane czynności",
            question3:
              "Staż pracy na obecnym stanowisku w " +
              "zakładzie pracy zajmującym się działalnością koncesjonowaną",
            question4a: "Udział w wypadku w miejscu pracy",
            question4b:
              "Jakie były przyczyny, konsekwencje wypadku - czy nastąpiło uszkodzenie mienia, zdrowia?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Podstawowe cele wykorzystania broni:",
            question1g: "Jakie ?",
            question2:
              "Czy kiedykolwiek miało miejsce użycie broni " +
              "lub innych środków przymusu bezpośredniego?",
            question3:
              "Jak często miało miejsce użycie broni w ciągu ostatnich 5 lat?",
          },
          questionAnswers: {
            question1a: "do celów zawodowych (detektyw, pracownik ochrony)",
            question1b: "do celów łowieckich",
            question1c: "do celów sportowych",
            question1d: "do użytku osobistego",
            question1e: "do celów muzealnych, pamiątkowych, naukowych",
            question1f: "inne",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Search participant",
    placeholderMobile: "Search",
    placeholderDesktop:
      "First name, last name, PESEL/ID, date of assessment assignment (YYYY-MM-DD)",
    noResults: "No search results",
    noResultsForFilter:
      "No participants were found matching the entered criteria",
    searchForExaminees: "Search participant",
    enterFilter:
      "You can search by first name, last name. PESEL/ID {...} or  by  date of assessment assignment {...} in the format YYYY-MM-DD.",
  },
  Snackbars: {
    createExamineeSuccess: "Participant added successfully",
    editExamineeSuccess: "Participant edited successfully",
    failedRequest: "Query failed",
    movingExamineesSuccess: "Moving to another folder was successful",
    passwordChangeSuccess: "The password change was successful",
    pinChangeSuccess: "The PIN number change was successful",
    success: "The action was successful",
    unrecognizedError: "Unrecognized error, please check your internet connection",
    createExaminationSuccess: "The assessment was assigned correctly",
    deleteExaminationSuccess: "The assessment was deleted correctly",
  },
  UserSettings: {
    header: "Account settings",
    headerChangePassword: "Change password",
    headerChangePin: "Change PIN",
    setNewPin: "Set new PIN",
    pinInfo:
      "Enter the PIN number that you will use to log in to the administrator panel.",
    headerMobile: "Settings",
    facilityInfo: "Psychology laboratory data",
    userInfo: "User data",
    passwordChange: "Change password",
    passwordExpiry: "Your password expires:",
    pinChange: "Change PIN",
    infoSubHeaders: {
      labName: "Name",
      address: "Address",
      cin: "Licence No",
      tin: "NIP",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      licenseNumber: "Licence No",
    },
    helpdeskMsgUser:
      "If you want to change your user details, call the number",
    helpdeskMsgLab: "If you want to change psychology laboratory data, call the number",
  },
  Backend: {
    User_activation_code_has_already_been_used:
      "The activation code has already been used.",
    User_activation_code_does_not_exist: "The provided activation code does not exist.",
    User_with_given_id_does_not_exist: "The user with the given id does not exist.",
    Token_not_recognized: "The token was not recognized.",
    Unrecognized_token_Please_regenerate_password_reset_link:
      "Unrecognized token, please generate a new password reset link.",
    Invalid_token_Please_regenerate_password_reset_link:
      "Invalid token, please generate a new password reset link.",
    Validation_failed: "Validation of entered data failed.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee:
      "It was not possible to link the user's facility with the participant.",
    Could_not_bind_users_PsychologicalLab_with_the_directory:
      "It was not possible to link the user's facility with the folder.",
    The_password_cannot_be_the_same_as_the_previous_one:
      "The password cannot be the same as the previous one.",
    Incorrect_credentials: "Invalid password",
    Unrecognized_email:
      "The email address was not recognized or the account was not activated.",
    Directory_has_related_Examinees:
      "Unable to delete Folder Folder has participants associated with it.",
    Unable_to_delete_default_directory:
      "It is not possible to delete the Default folder.",
    The_new_pin_cannot_be_the_same_as_the_previous_one:
      "The new PIN cannot be the same as the previous one.",
    Directory_with_given_name_already_exists:
      "A folder with this name already exists.",
    Examination_Card_does_not_have_a_defined_NormSet:
      "The examination card does not have an assigned norm.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab:
      "A participant with the given ID already exists.",
    The_Examinee_has_related_Examinations:
      "Unable to delete Participant Participant has assessments associated with it.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data:
      "Unrecognized error downloading archival assessments.",
    Legacy_data_instance_not_found: "No archived data found.",
    Legacy_data_validation_failed: "Validation error: archive data.",
    Unable_to_parse_Examination_Aim: "Validation error: Purpose of assessment.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data:
      "Validation error: Assessment - Occupational Medicine.",
    Ongoing_examination: "It is not possible to delete the assessment. Assessment in progress.",
  },
  Onboarding: {
    errorText:
      "An error occurred while setting your password, please try again later.",

    successTitle: "The password and PIN have been set",
    success:
      "Your password and PIN have been saved. You can now log in to the application for the first time using your email address and password.",
    setNewPasswordInfo:
      "Enter the password you will use to log in to the system.",
    setPasswordFormLabel: "Set a password",
    setPinInfo:
      "Enter the PIN number you will use to log in to the administrator panel.",
    setPinFormLabel: "Set PIN",
  },
  ResetPassword: {
    formHeader: "Reset password",
    resetInfo:
      "Enter your account email address to receive a password reset link.",
    resetEmailSentPt1: "E-mail adress:",
    resetEmailSentPt2:
      "to which a message was sent containing instructions on the password reset process.",
    errorText:
      "An error occurred while resetting your password, please try again later.",
    successTitle: "Password was saved",
    success:
      "Your password has been changed. You can now log in to the application using your email address and new password.",
    setNewPassword: "Set a new password",
    setNewPasswordInfo:
      "Enter the password you will use {...} to log in to the system.",
  },
  FormLabels: {
    email: "E-mail",
    password: "Password",
    currentPassword: "Current password",
    newPassword: "New password",
    pin: "PIN",
  },
  FormValidationErrors: {
    required: "This field is required",
    email: "Wrong email address value",
  },
  Login: {
    header: "LOGIN",
    error: "Wrong e-mail or password.",
    warning: "CAPS LOCK on",
    reset: "Reset password",
    Instance_configured_incorrectly:
      "System configuration error. Report a problem to technical support.",
    No_recaptcha_token_provided:
      "Recaptcha error. Report a problem to technical support.",
    Validation_failed: "Validation of entered data failed.",
    Captcha_error: "Captcha filling error. Try again.",
  },
  Directory: {
    defaultFolderName: "Default folder",
    header: "Managing Participant folders",
    popup: {
      create: "Enter a folder name",
      edit: "Change folder name",
    },
    emptyState: {
      header: "No participants in the selected folder",
      info: "To see the list of participantsin a folder, add at least one participant to it",
    },
    labels: {
      name: "Folder name",
      owner: "Owner",
      examineeCount: "Examinee",
    },
    kebabMenu: {
      addExaminee: "Add examinee",
      addDirectory: "Add folder",
      edit: "Edit folder",
      delete: "Delete folder",
    },
    popups: {
      delete: {
        confirmationHeader: "Are you sure you want to delete the folder?",
        confirmationText:
          "Once you delete a folder, you won't be able to restore it.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Move participants",
    labels: {
      firstName: "First name",
      lastName: "Last name",
      peselSlashIdentifier: "PESEL / ID",
      lastModification: "Last modification",
    },
    kebabMenu: {
      edit: "Edit participant",
      move: "Move participant",
      delete: "Delete participant",
    },
    breadcrumbs: "Managing Participant folders",
  },
  Pagination: {
    outOf: "of",
  },
  PasswordExpired: {
    popup: {
      header: "Your password has expired.",
      text: "To set a new password, you will be taken to the new password setting process.",
    },
    form: {
      header: "Set a new password",
      text: "Enter the password you will use to log in to the system.",
    },
    success: {
      header: "Password was saved",
      text: "Your password has been changed. You can now log in to the application.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Pomyślnie zapisano dokument",
    },
    names: {
      ExaminationCard: "Karta badania",
      ExaminationCardTEST2WORK: "Karta badania TEST2WORK",
      ExaminationCardTEST2SAFE: "Karta badania TEST2SAFE",
      ExaminationCardTEST2ARMS: "Karta badania TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Karta badania TEST2DRIVE",
      PsychologicalConsultation: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2WORK: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2SAFE: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2ARMS: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2DRIVE: "Konsultacja psychologiczna",
      PsychologicalOpinion: "Opinia psychologiczna",
      PsychologicalOpinionTEST2WORK: "Opinia psychologiczna",
      PsychologicalOpinionTEST2SAFE: "Opinia psychologiczna - detektywi",
      PsychologicalOpinionTEST2ARMS: "Opinia psychologiczna",
      PsychologicalOpinionTEST2DRIVE: "Opinia psychologiczna",
      CivilExplosivesJudgment: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2WORK: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2SAFE: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2ARMS: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2DRIVE: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      DriverJudgment: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2WORK: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2SAFE: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2ARMS: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2DRIVE: "Orzeczenie kierowanie pojazdami",
      DrivingExaminerJudgment: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2WORK: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2SAFE: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2ARMS: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2DRIVE: "Orzeczenie egzaminator jazdy",
      DrivingInstructorJudgment: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2WORK: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2SAFE: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2ARMS: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2DRIVE: "Orzeczenie instruktor jazdy",
      DrivingTechniqueInstructorJudgment: "Orzeczenie instruktor techniki jazdy",
      EmergencyVehicleDriverJudgment: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Orzeczenie pojazdy uprzywilejowane",
      FirearmLicenseJudgment: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2WORK: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2SAFE: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2ARMS: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2DRIVE: "Orzeczenie pozwolenie na broń",
      MilitaryExplosivesJudgment: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2WORK: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2SAFE: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2ARMS: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Orzeczenie broń i materiały policyjne lub wojskowe",
      OccupationalMedicineJudgmentTEST2WORK: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2SAFE: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2ARMS: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2DRIVE: "Orzeczenie medycyna pracy",
      PhysicalProtectionJudgment: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2WORK: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2SAFE: "Orzeczenie - pracownik ochrony fizycznej",
      PhysicalProtectionJudgmentTEST2ARMS: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2DRIVE: "Orzeczenie licencja ochroniarza",
      ProfessionalDriverJudgment: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2WORK: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2SAFE: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2ARMS: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2DRIVE: "Orzeczenie stanowisko kierowcy",
    },
    emptyState: {
      header: "Badanie nie zostało zakończone",
    },
    report: {
      details: {
        assignDate: "Assigned date",
        endDate: "Ended Date",
        firstName: "First name",

        PESEL: "PESEL",
        identificationNumber: "PESEL / ID",
        idExamination: "Assessment ID",
        lastName: "Last name",
        name: "Assessment name",
        status: "Assessment status",
      },
      statusExamination: {
        skip: "Pominięto lub przerwano niektóre testy",
      },
      header: {
        language: "Select report language",
        standard: "Select norm",
        recipient: "Wybierz odbiorcę",
        interval: "Select a confidence interval",
        reportInterpretation: "Interpretative report",
        reportDiagnostic: "Diagnostic report",
      },
    },
    consultation: {
      breadcrumbs: "Konsultacja psychologiczna",
      examineeData: "Dane badanego",
      statement: "Stwierdzam*",
    },
    opinion: {
      breadcrumbs: "Opinia psychologiczna",
      breadcrumbsAppeal: "Opinia psychologiczna odwoławcza",
      examineeData: "Dane badanego",
      statement:
        "NA PODSTAWIE PRZEPROWADZONEGO BADANIA PSYCHOLOGICZNEGO STWIERDZAM",
    },
    judgment: {
      isAppealTitle: "ORZECZENIE ODWOŁAWCZE",
      appeal: "Od niniejszego orzeczenia służy prawo odwołania się do",
      appealDate: "W terminie",
      appealDay: "W terminie",
      appealDay2: "dni od dnia jego otrzymania",
      appealDayTlt: "Liczba dni",
      drivingLicense: "W zakresie prawa jazdy kategorii",
      judgmentNumber: "Orzeczenie psychologiczne nr",
      judgmentNumberAppeal: "Orzeczenie psychologiczne nr",
      legalBais: "Podstawa prawna badania",
      statement: "Stwierdzam*",
      occupationalMedicineStatement: "Orzekam*",
      statementThat: "Stwierdzam, że",
      nextLegalBasis: "Podstawa prawna następnego badania",
      remarks: "Uwagi",
      ART_1: "art. 39k ust. 3 pkt 1",
      ART_2: "art. 39k ust. 3 pkt 2",
      examiner_ART_1: "art. 34 ust. 5 pkt 1",
      examiner_ART_2: "art. 34 ust. 5 pkt 2",
      header: {
        base: "Orzeczenie",
        baseAppeal: "Orzeczenie odwoławcze",
        baseAppealFooter: "Niniejsze orzeczenie jest ostateczne.",
        civilExplosives: "Działalność koncesjonowana - do użytku cywilnego",
        civilExplosivesAppeal: "Działalność koncesjonowana - do użytku cywilnego - odwołanie",
        driver: "Kierowanie pojazdami",
        emergencyVehicleDriver: "Kierowca pojazdu uprzywilejowanego",
        firearmLicense: "Pozwolenie na broń",
        militaryExplosives:
          "Działalność koncesjonowana - o przeznaczeniu wojskowym lub policyjnym",
        physicalProtection: "Pracownik ochrony",
        profesionalDriver: "Stanowisko kierowcy",
        drivingInstructor: "Instruktor",
        drivingExaminer: "Egzaminator",
        occupationalMedicine: "Medycyna pracy",
      },
      emergencyVehicleDriver: {
        true: "brak przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym wartości pieniężne",
        false:
          "istnienie przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym wartości pieniężne",
      },
      drivingVehicles: {
        true: "brak przeciwwskazań psychologicznych kierowania pojazdami",
        false: "istnienie przeciwwskazań psychologicznych kierowania pojazdami",
      },
      physicalProtection: {
        true: "posiada zdolność do wykonywania zadań pracownika ochrony fizycznej",
        false:
          "nie posiada zdolności do wykonywania zadań pracownika ochrony fizycznej",
      },
      civilExplosives: {
        true: "nie wykazuje zaburzeń funkcjonowania psychologicznego, a tym samym może wykonywać działalność w zakresie nabywania oraz przechowywania materiałów wybuchowych przeznaczonych do użytku cywilnego",
        false:
          "wykazuje zaburzenia funkcjonowania psychologicznego, a tym samym nie może wykonywać działalności w zakresie nabywania oraz przechowywania materiałów wybuchowych przeznaczonych do użytku cywilnego",
      },
      drivingInstructor: {
        true: "brak przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
        false:
          "istnienie przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
      },
      drivingExaminer: {
        true: "brak przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
        false: "istnienie przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
      },
      militaryExplosives: {
        true: "nie wykazuje istotnych zaburzeń funkcjonowania psychologicznego, które stanowią przeszkodę do wykonywania lub kierowania działalnością gospodarczą w zakresie wytwarzania i obrotu materiałami wybuchowymi, bronią, amunicją oraz wyrobami i technologią o przeznaczeniu wojskowym lub policyjnym",
        false:
          "wykazuje istotne zaburzenia funkcjonowania psychologicznego, które stanowią przeszkodę do wykonywania lub kierowania działalnością gospodarczą w zakresie wytwarzania i obrotu materiałami wybuchowymi, bronią, amunicją oraz wyrobami i technologią o przeznaczeniu wojskowym lub policyjnym",
      },
      professionalDriver: {
        true: "brak przeciwwskazań psychologicznych do wykonywania pracy na stanowisku kierowcy",
        false:
          "istnienie przeciwwskazań psychologicznych do wykonywania pracy na stanowisku kierowcy",
      },
      firearmLicense: {
        true: "nie należy do osób wymienionych w art. 15 ust. 1 pkt 3 ustawy z dnia 21 maja 1999 r. o broni amunicji i może dysponować bronią",
        false:
          "należy do osób wymienionych w art. 15 ust. 1 pkt 3 ustawy z dnia 21 maja 1999 r. o broni amunicji i nie może dysponować bronią",
      },
      occupationalMedicine: {
        true: "brak przeciwwskazań psychologicznych do",
        false: "istnienie przeciwwskazań psychologicznych do",
        justification: "Uzasadnienie",
      },
    },
    ExaminationCard: {
      header: "Karta badania",
      headerTEST2WORK: "Karta badania TEST2WORK",
      headerTEST2SAFE: "Karta badania TEST2SAFE",
      headerTEST2ARMS: "Karta badania TEST2ARMS",
      headerTEST2DRIVE: "Karta badania TEST2DRIVE",
      fetchUpdated: "Aktualizuj danymi z wywiadu i wynikami",
      tooltipFetchUpdated:
        "Ta akcja usunie wszystkie niezapisane zmiany z Przebiegu Badania",
      tooltipFetchUpdatedDisabled:
        "W celu odblokowania akcji wybierz normę badania.",
      tabs: {
        examinee: "Osoba badana",
        courseOfExamination: "Przebieg badania",
        results: "Wyniki badania psychologicznego",
      },
      examinee: {
        chosenNormSet: "Norma zastosowana w badaniu",
        examinationDate: "Data Badania",
        previousExaminationDate: "Data poprzedniego badania",
        aim: "Cel badania",
        entityDirectingToExamination: "Podmiot kierujący na badanie",
        t2dAim: {
          drivingVehiclesAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do kierowania pojazdami.",
          professionalDriverAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do wykonywania pracy na stanowisku kierowcy.",
          emergencyDriverAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym środki pieniężne.",
          drivingInstructorAim:
            "Stwierdzenie braku lub istnienia przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy.",
        },
      },
      examinationCourse: {
        forcedUpdateHeader:
          "Zaktualizuj Kartę Badania danymi z Wywiadu i Wynikami",
        forcedUpdateBody:
          "W celu odblokowania formularza wypełnij Wywiad dla Badania, wybierz normę w zakładce Osoba Badana i zaktualizuj Kartę Badania danymi z Wywiadu i Wynikami.",
        header: "Dane z wywiadu bezpośredniego i obserwacji osoby badanej",
        methodology: "Metody badania i wyniki",
        recommendations: "Zalecenia",
        conclusions: "Wnioski",
        conclusionsRecomendation: "Wnioski i zalecenia",
        careerHistory: "Przebieg pracy",
        examinationResultsHeader:
          "Nazwa testu i wyniki (w ramach rekomendowanej metodyki)",
        examinationExtendedResultsHeader: "Nazwa testu i wyniki dodatkowe",
        cognitiveResultsHeader:
          "Ocena i opis osoby badanej pod względem sprawności intelektualnej i procesów poznawczych (w ramach rekomendowanej metodyki)",
        cognitiveExtendedResultsHeader:
          "Ocena i opis osoby badanej pod względem sprawności intelektualnej i procesów poznawczych wynki dodatkowe",
        personalityResultsHeader:
          "Ocena i opis osoby badanej pod względem osobowości, z uwzględnieniem funkcjonowania w trudnych sytuacjach, a także dojrzałości społecznej (w ramach rekomendowanej metodyki)",
        personalityExtendedResultsHeader:
          "Ocena i opis osoby badanej pod względem osobowości, z uwzględnieniem funkcjonowania w trudnych sytuacjach, a także dojrzałości społecznej wyniki dodatkowe",
        psychomotorResultsHeader:
          "Sprawność psychomotoryczna (w ramach rekomendowanej metodyki)",
        psychomotorExtendedResultsHeader:
          "Sprawność psychomotoryczna wyniki dodatkowe",
        occupationalMedicineAdditionalInfo:
          "Dodatkowe informacje - Medycyna pracy",
        examinationResultsT2WHeader: "Rekomendowana metodyka",
        examinationExtendedResultsT2WHeader: "Dodatkowe",
        addExaminationExtendedResults: "Dodaj wynik dodatkowy",
        deletePopup: {
          headerText: "Czy na pewno chcesz usunąć wynik?",
          contentText:
            "Po usunięciu wyniku można przywtucić jego orginalną treść poprzez aktualizację danych",
          confirmationText: "Tak, chcę usunąć wynik",
        },
      },
      judgment: {
        result: "Wynik badania psychologicznego",
        sent: "Orzeczenie/kopię orzeczenia wysłano, jeżeli dotyczy",
        judgment: "Treść orzeczenia",
        justification: "Uzasadnienie orzeczenia",
        justificationText: "Treść uzasadnienia",
        recommendations: "Zalecenia",
        to: "do",
        t2d: {
          lack: "brak",
          existing: "istnieje",
          questions: {
            drivingVehiclesContraindication:
              "Przeciwwskazania do kierowania pojazdami.",
            professionalDriverContraindication:
              "Przeciwwskazania do wykonywania pracy na stanowisku kierowcy.",
            emergencyVehicleDriverContraindication:
              "Przeciwwskazania do kierowania pojazdem uprzywilejowanym lub przewożącym środki pieniężne.",
            drivingInstructorContraindication:
              "Przeciwwskazania do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy.",
          },
        },
      },
    },
  },
};
